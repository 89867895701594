import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import MapPage from './pages/MapPage';
import Intangles from '../shared/sdk';
function NestedRouter(props) {
    var params = new URLSearchParams(window.location.search);
    var token = params.get('token');
    Intangles.setConfig({
        token: token
    });
    return (
        <Router>
            <Route path="/:vehiclePlate" component={MapPage}/>
        </Router>
    )
}

export default NestedRouter;