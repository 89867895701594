import colorGradient from "./helpers/shadeGenerator";
const theme = {
    LINK_COLOR: '#0366d6',
    PRIMARY_COLOR: '#293846',
    SECONDARY_COLOR: '#00B1B6',
    INVERTED_TEXT: '#fff',
    DEFAULT_TEXT: '#000',
    SECONDARY_TEXT: '#6c757d',
    DANGER_COLOR: '#B00020',
    CRITICAL_COLOR: '#d9534f',
    GOOD_COLOR: '#5cb85c',
    MAJOR_COLOR: '#e67e22',
    MINOR_COLOR: '#f1c40f',
    HEALTH_MINOR: '#f1c40f',
    HEALTH_GOOD: '#5cb85c',
    HEALTH_MAJOR: '#e67e22',
    HEALTH_CRITICAL: '#d9534f',
    DEFAULT_BUTTON: '#979797',
    MUTED_TEXT: '#979797',
    MAIN_BACKGROUND_COLOR: '#fff',
    SECONDARY_BACKGROUND_COLOR: '#f5f5f5',
    DARK_BACKGROUND_COLOR: 'rgba(0,0,0,0.38)',
    DARKER_BACKGROUND_COLOR: 'rgba(0,0,0,0.54)',
    DARKEST_BACKGROUND_COLOR: '#979797',
    INACTIVE_COLOR: '#bbb',
    LIGHT_TEXT: '#ddd',
    FOOTER_COLOR: 'rgba(242,244,248,1)',
    SELECTED_FOOTER_COLOR: 'rgba(230, 232, 236,1)',
    secondaryShades: function (shade = 1) {
        return colorGradient(shade,
            { red: 255, green: 255, blue: 255 },
            { red: 0, green: 177, blue: 186 }
        )
    },
    primaryShades: function (shade = 1) {
        return colorGradient(shade,
            { red: 255, green: 255, blue: 255 },
            { red: 41, green: 56, blue: 70 }
        )
    },
    FONT: 14,
    SMALL_FONT: 12,
    LARGE_FONT: 16,
    XLARGE_FONT: 18,
    XXLARGE_FONT: 20,
    XSMALL_FONT: 10,
};
export default theme;