import Vehicle from '../classes/Vehicle';
// import moment from 'moment';
import format from 'date-fns/format';
import {getYear} from 'date-fns';
import { normalizeVehicles } from './normalizr';
var model = {};
var algoNames = ['ect', 'alternator'];
export let toUIVehicleList = function (svcList, noMapView) {
	var obj = {
			list: svcList.v,
			paging: {}
		},
		_this = this;
	// svcList.v.forEach(function (itm) {
	// 	if (itm.plate || itm.tag) {
	// 		if (!noMapView) {
	// 			if (itm.last_state)
	// 				obj.list.push(new Vehicle(itm));
	// 		} else {
	// 			obj.list.push(new Vehicle(itm));
	// 		}
	// 	} else {
	// 		if (noMapView) {
	// 			obj.list.push(new Vehicle(itm));
	// 		}
	// 	}
	// });
	if (svcList.paging) {
		obj.paging = {
			pnum: svcList.paging.pnum,
			total: svcList.paging.total,
			psize: svcList.paging.psize,
			start: ((svcList.paging.pnum - 1) * svcList.paging.psize) + 1,
			end: svcList.paging.pnum * svcList.paging.psize,
			pages: Math.ceil(svcList.paging.total / svcList.paging.psize)
		};

		if (obj.paging.end > obj.paging.total)
			obj.paging.end = obj.paging.total;
	}
	var normalizedVehiclesList = normalizeVehicles(obj);
	obj = normalizedVehiclesList.result;
	obj.entities = normalizedVehiclesList.entities;
	return obj;
};

export function getCodesSummary(codes) {
	var summaryObj = {
		live_count: 0,
		stored_count: 0,
		live_codes: [],
		stored_codes: [],
		uniqueCodeStrings: []
	};
	if(!codes)
		return summaryObj;
	var dedupeDtcs = dedupeCodes(codes);

	for (var i = 0; i < dedupeDtcs.length; i++) {
		var code = dedupeDtcs[i];
		var c = code.code;
		var codeString = c;

		if (code.hasOwnProperty("is_sid") && code.is_sid === true) {
			codeString = codeString + "|1";
		}
		if (code.hasOwnProperty("fmi") && code.fmi !== undefined && code.fmi !== null) {
			codeString += "-" + code.fmi;
		}
		if (code.hasOwnProperty("mid") && code.mid !== undefined && code.mid !== null) {
			//Assuming all codes with sid will have an fmi
			codeString += "@" + code.mid;
		}
		code.codeString = codeString;
		code.displayCode = code.code.split('-')[0];
		summaryObj.uniqueCodeStrings.push(codeString);
		if (code.status === 1) {
			summaryObj.live_codes.push(code);
			summaryObj.live_count++;
		}
		else {
			summaryObj.stored_codes.push(code);
			summaryObj.stored_count++;
		}
	}
	return summaryObj;
}

function dedupeCodes(array) {
	return array.reduce(function (prev, current, index) {
		var dupe = false;
		prev.forEach(function (item) {
			if (current.mid == item.mid && current.is_sid == item.is_sid && current.code == item.code && current.fmi == item.fmi)
				dupe = true;
		})
		if (!dupe)
			prev.push(current);
		return prev;
	}, []);
}

// function getAlertForListing(obj) {
// 	var toReturn = [];
// 	for (var algo in obj) {
// 		if (obj.hasOwnProperty(algo) === false)
// 			continue;

// 		if (algoNames.indexOf(algo) > -1
//             && obj[algo]
//             && obj[algo].algos
//             && obj[algo].algos.health_status > 0
// 		) {
// 			var msg = obj[algo].algos.alert_msg ? obj[algo].algos.alert_msg : obj[algo].algos.alert;
// 			toReturn.push(toSentenceCase(msg));

// 		}
// 	}
// 	if (toReturn.length === 0)
// 		return ['No Issues'];

// 	return toReturn;
// }
// function getDistanceForUI(row) {
// 	var distance = getDistance(row);
// 	var km = distance / 1000.0;
// 	return Math.round(km);
// 	// return Math.round(km);
// }

export function getTimeForReport(timestamp) {
	var current = new Date();
	var t = parseInt(timestamp);
	// var format = "ddd, MMM Do, h:mm a";
	var formatString = 'MMM Do, h:mm:ss a';
	if (getYear(current) !== getYear(t)) {
		formatString = 'ddd, MMM Do YYYY, h:mm:ss a';
	}
	return format(t,formatString);
}

// function getTimeForOBDReport(timestamp) {
// 	var current = new Date();
// 	var t = parseInt(timestamp);
// 	// var format = "ddd, MMM Do, h:mm a";
// 	var formatString = 'MMM Do, h:mm a';
// 	if (getYear(current) !== getYear(t)) {
// 		formatString = 'ddd, MMM Do YYYY, h:mm a';
// 	}
// 	return format(t,formatString);
// }

// function getDistance(row) {
// 	var distance;
// 	if (row.start_hub === row.end_hub) {
// 		distance = row.device_distance;
// 	} else {
// 		distance = row.distance;
// 	}
// 	return distance;
// }

// function getTotalTravelTime(startTime, endTime) {
// 	var time = endTime - startTime;
// 	return millisecondsToMinutes(time);

// }

// export function secondsToMinutes(seconds) {
// 	if (!seconds)
// 		return '-';
// 	var minutes = seconds / 60;
// 	if (minutes > 60) {

// 		var hours = Math.floor(minutes / 60);
// 		var min = minutes - (hours * 60);
// 		var hourPrefix = ' Hrs';
// 		if (hours === 1) {
// 			hourPrefix = ' Hr';
// 		}
// 		var minPrefix = ' Mins';
// 		if (min <= 1) {
// 			minPrefix = ' Min';
// 		}
// 		var time = hours.toFixed(0).toString() + hourPrefix + ' ' + min.toFixed(0).toString() + minPrefix;
// 		return time;
// 	} else
// 		return minutes.toFixed(0).toString() + ' Mins';
// }

// function millisecondsToMinutes(milliseconds) {
// 	var minutes = milliseconds / 1000 / 60;
// 	if (minutes > 60) {

// 		var hours = Math.floor(minutes / 60);
// 		var min = minutes - (hours * 60);
// 		var hourPrefix = ' Hrs';
// 		if (hours === 1) {
// 			hourPrefix = ' Hr';
// 		}
// 		var minPrefix = ' Mins';
// 		if (min <= 1) {
// 			minPrefix = ' Min';
// 		}
// 		var time = hours.toFixed(0).toString() + hourPrefix + ' ' + min.toFixed(0).toString() + minPrefix;
// 		return time;
// 	} else
// 		return minutes.toFixed(0).toString() + ' Mins';
// }

// function getAverageSpeed(row) {
// 	if (row.avg_speed) {
// 		return Math.round(row.avg_speed);
// 	}
// 	var distance = getDistance(row);
// 	var startTime = row.start_time;
// 	var endTime = row.end_time;
// 	var dKM = distance / 1000;
// 	var timeHR = (endTime - startTime) / 1000 / 60 / 60;
// 	var avg = dKM / timeHR;
// 	return Math.round(avg);
// }

// function getFuelConsumed(fuelConsumed) {
// 	if (!fuelConsumed)
// 		return undefined;
// 	if (typeof fuelConsumed === 'string') {
// 		fuelConsumed = parseFloat(fuelConsumed);
// 	}
// 	fuelConsumed = fuelConsumed.toFixed(1);
// 	return fuelConsumed;
// }
// // export let toUIH2HReport = function (svcList) {

// // 	var obj = {
// // 			report: []
// // 		},
// // 		_this = this;

// // 	angular.forEach(svcList.reports, function (itm) {

// // 		var rep = {
// // 			vehicle_plate: itm.vehicle_plate,
// // 			vehicle_type: itm.vehicle_type,
// // 			start_time: getTimeForReport(itm.start_time),
// // 			end_time: getTimeForReport(itm.end_time),
// // 			startTime: itm.start_time,
// // 			endTime: itm.end_time,
// // 			start_hub: itm.start_hub,
// // 			end_hub: itm.end_hub,
// // 			distance: getDistanceForUI(itm),
// // 			total_time: getTotalTravelTime(itm.start_time, itm.end_time),
// // 			avg_speed: getAverageSpeed(itm),
// // 			exceptions: itm.exceptions,
// // 			vehicle_tag: itm.vehicle_tag,
// // 			vid: itm.vehicle_id,
// // 			start_time_epoch: itm.start_time,
// // 			end_time_epoch: itm.end_time,
// // 			fuel_consumed: getFuelConsumed(itm.fuel_consumed)
// // 		};



// // 		if (parseFloat(rep.distance) > 0) {
// // 			if (parseFloat(rep.fuel_consumed) > 0) {
// // 				var mileage = (rep.distance / rep.fuel_consumed).toFixed(1);
// // 				rep.mileage = mileage;
// // 			}
// // 			else {
// // 				rep.mileage = '-';
// // 			}
// // 			obj.report.push(rep);
// // 		}

// // 	});

// // 	obj.paging = {
// // 		pnum: svcList.paging.pnum,
// // 		total: svcList.paging.total,
// // 		psize: svcList.paging.psize,
// // 		start: ((svcList.paging.pnum - 1) * svcList.paging.psize) + 1,
// // 		end: svcList.paging.pnum * svcList.paging.psize,
// // 		pages: Math.ceil(svcList.paging.total / svcList.paging.psize)
// // 	};

// // 	if (obj.paging.end > obj.paging.total)
// // 		obj.paging.end = obj.paging.total;

// // 	return obj;

// // };


// export let toUIDailyReport = function (svcList) {

// 	var obj = {
// 			report: []
// 		},
// 		_this = this;

// 	for (var i = 0; i < svcList.reports.length; i++) {
// 		var itm = svcList.reports[i];

// 		var rep = {
// 			vehicle_plate: itm.vehicle_plate,
// 			vehicle_type: itm.vehicle_type,
// 			start_time: getTimeForReport(itm.start_time),
// 			end_time: getTimeForReport(itm.end_time),
// 			startTime: itm.start_time,
// 			endTime: itm.end_time,
// 			distance: Math.round(getDistanceForDailyReport(itm, itm.vehicle_protocol)),
// 			moving_time: millisecondsToMinutes(itm.moving_time),
// 			idle_time: millisecondsToMinutes(itm.idle_time),
// 			vehicle_tag: itm.vehicle_tag,
// 			vid: itm.vehicle_id,
// 			fuel_consumed: getFuelConsumed(itm.fuel_consumed),
// 			over_speed_count: itm.over_speed_count,
// 			hard_brake_count: itm.hard_brake_count,
// 			over_accel_count: itm.over_accel_count,
// 			idling_acount: itm.idling_acount
// 		};
// 		if (i > 0 && itm.vehicle_plate === svcList.reports[i - 1].vehicle_plate) {
// 			rep.vehicle_tag = null;
// 		}
// 		obj.report.push(rep);
// 	}


// 	obj.paging = {
// 		pnum: svcList.paging.pnum,
// 		total: svcList.paging.total,
// 		psize: svcList.paging.psize,
// 		start: ((svcList.paging.pnum - 1) * svcList.paging.psize) + 1,
// 		end: svcList.paging.pnum * svcList.paging.psize,
// 		pages: Math.ceil(svcList.paging.total / svcList.paging.psize)
// 	};

// 	if (obj.paging.end > obj.paging.total)
// 		obj.paging.end = obj.paging.total;

// 	return obj;

// };

// var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];


// export let toUIMonthlyReport = function (svcList) {

// 	var obj = {
// 			report: []
// 		},
// 		_this = this;

// 	for (var i = 0; i < svcList.reports.length; i++) {
// 		var itm = svcList.reports[i];

// 		var rep = {
// 			vehicle_plate: itm.vehicle_plate,
// 			vehicle_type: itm.vehicle_type,
// 			start_time: getTimeForReport(itm.start_time),
// 			end_time: getTimeForReport(itm.end_time),
// 			year: itm.year,
// 			month: months[itm.month - 1],
// 			distance: Math.round(getDistanceForDailyReport(itm, itm.vehicle_protocol)),
// 			moving_time: millisecondsToMinutes(itm.moving_time),
// 			idle_time: millisecondsToMinutes(itm.idle_time),
// 			vehicle_tag: itm.vehicle_tag,
// 			vid: itm.vehicle_id,
// 			fuel_consumed: getFuelConsumed(itm.fuel_consumed),
// 			over_speed_count: itm.over_speed_count,
// 			hard_brake_count: itm.hard_brake_count,
// 			over_accel_count: itm.over_accel_count,
// 			idling_acount: itm.idling_acount
// 		};
// 		if (i > 0 && itm.vehicle_plate === svcList.reports[i - 1].vehicle_plate) {
// 			rep.vehicle_tag = null;
// 		}
// 		obj.report.push(rep);
// 	}


// 	obj.paging = {
// 		pnum: svcList.paging.pnum,
// 		total: svcList.paging.total,
// 		psize: svcList.paging.psize,
// 		start: ((svcList.paging.pnum - 1) * svcList.paging.psize) + 1,
// 		end: svcList.paging.pnum * svcList.paging.psize,
// 		pages: Math.ceil(svcList.paging.total / svcList.paging.psize)
// 	};

// 	if (obj.paging.end > obj.paging.total)
// 		obj.paging.end = obj.paging.total;

// 	return obj;

// };

// function getDistanceForDailyReport(tr, protocol) {
// 	if (protocol === 'AUTO, ISO 15765-4') {
// 		return tr.obd_distance / 1000;
// 	}
// 	if (tr.device_distance)
// 		return tr.device_distance / 1000;
// 	else if (tr.google_distance)
// 		return tr.google_distance / 1000;
// 	else if (tr.obd_distance)
// 		return tr.obd_distance;
// 	else
// 		return null;

// }
// // export let toUIHubStopReport = function (svcList) {

// // 	var obj = {
// // 			report: []
// // 		},
// // 		_this = this;

// // 	angular.forEach(svcList.reports, function (itm) {

// // 		obj.report.push({
// // 			vehicle_plate: itm.vehicle_plate,
// // 			vehicle_type: itm.vehicle_type,
// // 			start_time: getTimeForReport(itm.start_time),
// // 			end_time: getTimeForReport(itm.end_time),
// // 			hub: itm.hub,
// // 			distance: getDistanceForUI(itm.distance),
// // 			exceptions: itm.exceptions,
// // 			vehicle_tag: itm.vehicle_tag,
// // 			total_time: getTotalTravelTime(itm.start_time, itm.end_time)
// // 		});

// // 	});

// // 	obj.paging = {
// // 		pnum: svcList.paging.pnum,
// // 		total: svcList.paging.total,
// // 		psize: svcList.paging.psize,
// // 		start: ((svcList.paging.pnum - 1) * svcList.paging.psize) + 1,
// // 		end: svcList.paging.pnum * svcList.paging.psize,
// // 		pages: Math.ceil(svcList.paging.total / svcList.paging.psize)
// // 	};

// // 	if (obj.paging.end > obj.paging.total)
// // 		obj.paging.end = obj.paging.total;

// // 	return obj;

// // };

// function getAlertLogType(type) {
// 	switch (type.toLowerCase()) {
// 	case 'speeding':
// 		return 'Speeding';
// 	case 'stoppage':
// 		return 'Stoppage';
// 	case 'idling':
// 		return 'Idling';
// 	case 'hard_brake':
// 		return 'Hard Braking';
// 	case 'over_acceleration':
// 		return 'Harsh Acceleration';
// 	case 'geofence':
// 		return 'Geofence';
// 	case 'geofence':
// 		return 'Geofence';
// 	case 'routefence':
// 		return 'Route Fence';
// 	case 'free_run':
// 	case 'freerun':
// 		return 'Free Running';
// 	case 'night_driving':
// 		return 'Unscheduled Driving';
// 	case 'fuel_bhara':
// 		return 'Fuel Filled';
// 	case 'fuel_chori':
// 		return 'Fuel Theft';
// 	case 'def_bhara':
// 		return 'Adblue Filled';
// 	case 'def_chori':
// 		return 'Adblue Theft';
//         // case 'route_fence':
//         //     return 'Route Deviation';

// 	}
// }
// function getAlertObj(alertLog, accountId) {
// 	switch (alertLog.type.toLowerCase()) {
// 	case 'fuel_bhara':
// 		return {
// 			amount: alertLog.amount.toFixed(0),
// 			time: getTimeForReport(alertLog.timestamp),
// 			distance: alertLog.mileage_odo ? parseInt((alertLog.mileage_odo / 1000).toFixed(0)) : parseInt((alertLog.odo / 1000).toFixed(0)),
// 			total: parseInt((alertLog.amount + alertLog.start_level)).toFixed(0),
// 			fuel_filled_level: parseInt(alertLog.start_level).toFixed(0),
// 			verified: alertLog.verified,
// 			actual_filling: alertLog.actual_filling || {},
// 			mileage: alertLog.mileage
// 		};
// 	case 'fuel_chori':
// 		return {
// 			amount: alertLog.amount.toFixed(0),
// 			time: getTimeForReport(alertLog.timestamp),
// 			verified: alertLog.verified
// 		};
// 	case 'def_bhara':
// 		return {
// 			amount: alertLog.amount.toFixed(0),
// 			time: getTimeForReport(alertLog.timestamp),
// 			distance: parseInt((alertLog.odo / 1000).toFixed(0)),
// 			total: parseInt((alertLog.amount + alertLog.start_level)).toFixed(0),
// 			adblue_filled_level: parseInt(alertLog.start_level).toFixed(0),
// 			verified: alertLog.verified,
// 			actual_filling: alertLog.actual_filling || {}
// 		};
// 	case 'def_chori':
// 		return {
// 			amount: alertLog.amount.toFixed(0),
// 			time: getTimeForReport(alertLog.timestamp),
// 			verified: alertLog.verified
// 		};
// 	}
// }
// function getAlertText(alertLog, accountId, us, units) {
// 	switch (alertLog.type.toLowerCase()) {
// 	case 'stoppage':
// 		return getStopageText(alertLog, accountId);
// 	case 'speeding':
// 		return getSpeedText(alertLog, us, units);
// 	case 'idling':
// 		return getIdlingText(alertLog, accountId);
// 	case 'hard_brake':
// 		return getHardBrakeText(alertLog);
// 	case 'over_acceleration':
// 		return getHarshAccelerationText(alertLog);
// 	case 'geofence':
// 		return getGeofenceText(alertLog);
// 	case 'routefence':
// 		return getRoutefenceText(alertLog);
// 	case 'free_run':
// 	case 'freerun':
// 		return getFreeRunText(alertLog);
// 	case 'night_driving':
// 		return getUnscheduledDriving(alertLog);
// 	case 'fuel_bhara':
// 		return getFuelFilledText(alertLog);
// 	case 'fuel_chori':
// 		return getFuelPilferagedFilledText(alertLog);
// 	case 'def_bhara':
// 		return getAdblueFilledText(alertLog);
// 	case 'def_chori':
// 		return getAdbluePilferagedFilledText(alertLog);
// 	}
// }

// function getUnscheduledDriving(alertLog) {
// 	var startHour = alertLog.start_hr;
// 	var endHour = alertLog.end_hr;
// 	var startMinutes = alertLog.start_min;
// 	var endMinutes = alertLog.end_min;

// 	var start = getDisplayHour(startHour, startMinutes);
// 	var end = getDisplayHour(endHour, endMinutes);

// 	return ' did unscheduled driving between ' + start + ' and ' + end;

// }

// function getDisplayHour(hour, minutes) {
// 	var time;
// 	var meridiem = 'am';
// 	if (hour > 12) {
// 		meridiem = 'pm';
// 		hour = hour - 12;
// 	} else if (hour == 0) {
// 		hour = 12;
// 	}
// 	time = hour;
// 	if (minutes) {
// 		time += '.' + minutes;
// 	}
// 	time += ' ' + meridiem;
// 	return time;
// }

// function getFreeRunText(alertLog) {
// 	var duration = secondsToMinutes(alertLog.duration);
// 	if (alertLog.max_speed)
// 		var max_Speed = alertLog.max_speed.toFixed(0) + ' Km/Hr';
// 	else
// 		max_Speed = '';
// 	if (alertLog.distance)
// 		var distance = max_Speed + ',distance covered was ' + (alertLog.distance / 1000).toFixed(2) + ' Km';
// 	else
// 		distance = max_Speed;

// 	return ' did free running for ' + duration + ' and reached max speed of ' + distance;
// }

// function getGeofenceText(alertLog) {
// 	var motion = alertLog.motion.toLowerCase() === 'entry' ? 'entered' : 'went out of';
// 	var geofence = alertLog.geofence_tag;

// 	return ' ' + motion + ' ' + geofence;
// }

// function getRoutefenceText(alertLog) {
// 	var motion = alertLog.motion.toLowerCase() === 'entry' ? 'entered' : 'went out of';
// 	var routefence = alertLog.routefence_tag;

// 	return ' ' + motion + ' ' + routefence + ' route';
// }

// function getIdlingText(alertLog, accountId) {
// 	var idlingTime = secondsToMinutes(alertLog.idle_time);
// 	var startTime = getTimeForReport(alertLog.start_time);
// 	var vehicle = alertLog.vehicle_tag;
// 	var text = ' did idling for ' + idlingTime;

// 	//if (accountId === '135855620126540739' || accountId === '137749105930798241' || accountId === '141910274484601365' || accountId === '144458241775108917' || accountId === '145346741677458151'|| accountId === '149089795410363018' || accountId === '150795920868507963' || accountId === '152806228168409483' || accountId === '153603248580854082' || accountId === '154952553751249956' || accountId === '133743410819498733') {
// 	if (alertLog.engine && alertLog.engine.toLowerCase() === 'on') {
// 		text += ' with engine on,';
// 	} else {
// 		//    text += ' with engine off,';
// 	}
// 	// }
// 	return text;
// }

// function getStopageText(alertLog, accountId) {
// 	var durationMin = secondsToMinutes(alertLog.duration);
// 	var startTime = getTimeForReport(alertLog.timestamp);
// 	var vehicle = alertLog.vehicle_tag;
// 	var text = ' stopped for ' + durationMin;
// 	return text;
// }

// function getSpeedText(alertLog, us, units) {

// 	var time = getTimeForReport(alertLog.timestamp);
// 	var vehicle = alertLog.vehicle_tag;
// 	var speed = Math.round(alertLog.speed);
// 	var txt = us ? units.toMPH(speed) + ' miles/hr' : speed + ' km/hr ';
// 	return ' reached speed of ' + txt;
// }

// function getHarshAccelerationText(alertLog) {

// 	var time = getTimeForReport(alertLog.timestamp);
// 	var vehicle = alertLog.vehicle_tag;

// 	return ' did Harsh Acceleration ';
// }

// function getHardBrakeText(alertLog) {

// 	var time = getTimeForReport(alertLog.timestamp);
// 	var vehicle = alertLog.vehicle_tag;
// 	return 'did Hard Braking ';
// }

// function getFuelFilledText(alertLog) {
// 	var amount = alertLog.amount.toFixed(0);
// 	var string = 'Filled ' + amount + ' liters fuel';
// 	return string;
// }

// function getFuelPilferagedFilledText(alertLog) {
// 	var amount = alertLog.amount.toFixed(0);
// 	var string = 'Took out ' + amount + ' liters fuel';
// 	return string;
// }

// function getAdblueFilledText(alertLog) {
// 	var amount = alertLog.amount.toFixed(0);
// 	var string = 'Filled ' + amount + ' liters';
// 	return string;
// }

// function getAdbluePilferagedFilledText(alertLog) {
// 	var amount = alertLog.amount.toFixed(0);
// 	var string = 'Took out ' + amount + ' liters';
// 	return string;
// }

// function getAlertIcon(alertLog) {
// 	var iconUrl;
// 	switch (alertLog.type.toLowerCase()) {
// 	case 'speeding':
// 		iconUrl = 'sp_limit_no_text.png';
// 		break;
// 	case 'freerun':
// 		title = 'Free Run';
// 		iconUrl = 'alert_vehicle_small.png';
// 		break;
// 	case 'over_speed':
// 		iconUrl = 'sp_limit_no_text.png';
// 	case 'stoppage':
// 		iconUrl = 'stop_sign.png';
// 		break;
// 	case 'idling':
// 		title = 'Idling';
// 		iconUrl = 'stop_sign.png';
// 		break;
// 	case 'hard_brake':
// 		title = 'Hard Brake';
// 		iconUrl = 'alert_vehicle_small.png';
// 		break;
// 	case 'over_acceleration':
// 		iconUrl = 'alert_vehicle_small.png';
// 		title = 'Over Acceleration';
// 		break;
// 	case 'fuel_bhara':
// 		iconUrl = 'fuel_filled.png';
// 		title = 'Fuel Filled';
// 		break;
// 	case 'stoppage':
// 		iconUrl = 'stop_sign.png';
// 		break;
// 	}
// 	return '/css/img/alertmarkers/' + iconUrl;
// }

// function getIdleTime(alertlog) {
// 	if (alertlog.type !== 'idling')
// 		return null;
// 	return alertlog.idle_time;
// }
// export let toUIAlertLog = function (alertLogs, accountId, us, units, env) {

// 	var obj = {
// 			alerts: []
// 		},
// 		_this = this;

// 	for (var i = 0; i < alertLogs.logs.length; i++) {

// 		if (alertLogs.logs[i].location == undefined && env != 'blue')
// 			continue;

// 		obj.alerts.push({
// 			type: getAlertLogType(alertLogs.logs[i].type),
// 			info: getAlertText(alertLogs.logs[i], accountId, us, units),
// 			location: alertLogs.logs[i].address,
// 			vehicle: alertLogs.logs[i].vehicle_tag,
// 			time: getTimeForReport(alertLogs.logs[i].timestamp),
// 			geocode: alertLogs.logs[i].location,
// 			icon_url: getAlertIcon(alertLogs.logs[i]),
// 			timestamp: alertLogs.logs[i].timestamp,
// 			idle_time: getIdleTime(alertLogs.logs[i]),
// 			obj: getAlertObj(alertLogs.logs[i], accountId),
// 			fuel_consumed: alertLogs.logs[i].fuel_consumed,
// 			id: alertLogs.logs[i].id,
// 			verified: alertLogs.logs[i].verified,
// 			ignore: alertLogs.logs[i].ignore,
// 			speed: alertLogs.logs[i].speed,
// 			max_speed: alertLogs.logs[i].max_speed,
// 			duration: alertLogs.logs[i].duration,
// 			distance: alertLogs.logs[i].distance,
// 			actual_filling: alertLogs.logs[i].actual_filling,
// 		});
// 	}

// 	obj.paging = {
// 		pnum: alertLogs.paging.pnum,
// 		total: alertLogs.paging.total,
// 		psize: alertLogs.paging.psize,
// 		start: ((alertLogs.paging.pnum - 1) * alertLogs.paging.psize) + 1,
// 		end: alertLogs.paging.pnum * alertLogs.paging.psize,
// 		pages: Math.ceil(alertLogs.paging.total / alertLogs.paging.psize)
// 	};
// 	obj.counts = alertLogs.counts;

// 	if (obj.paging.end > obj.paging.total)
// 		obj.paging.end = obj.paging.total;

// 	return obj;

// };
// export let toUISelectiveAlertLog = function (alerts, plate, hard_brake, us, units) {
// 	var obj = {
// 		idling: [],
// 		overspeeding: [],
// 		freerunning: [],
// 		hard_braking: [],
// 		idlingCount: 0,
// 		overspeedingCount: 0,
// 		freerunCount: 0,
// 		total_idling_minutes: 0,
// 		total_freerunning_minutes: 0,
// 		fuel_during_idling: 0,
// 		hard_brake_count: 0
// 	};
// 	if (!alerts)
// 		return obj;
// 	var accountId = null;
// 	_this = this;
// 	if (alerts.post_trip_idling_eval) {
// 		obj.idlingCount = alerts.post_trip_idling_eval.length;
// 		obj.idling = alerts.post_trip_idling_eval.map(function (elem, index) {
// 			var item = elem.idling;
// 			item.type = 'idling';
// 			item.vehicle_tag = plate;
// 			item.idle_time = item.idleTime;
// 			obj.fuel_during_idling += item.fc;
// 			obj.total_idling_minutes += item.idleTime;
// 			return {
// 				type: item.type,
// 				vehicle: item.vehicle_tag,
// 				info: getAlertText(item, accountId, us, units),
// 				time: getTimeForReport(item.timestamp),
// 				geocode: item.location,
// 				timestamp: item.timestamp,
// 				idle_time: item.idleTime,
// 				fuel_consumed: item.fc,
// 				id: item.alert_id
// 			};
// 		});
// 		obj.fuel_during_idling = obj.fuel_during_idling.toFixed(2);
// 	}
// 	if (alerts.over_speed) {
// 		obj.overspeedingCount = alerts.over_speed.length;
// 		obj.overspeeding = alerts.over_speed.map(function (item, index) {
// 			//var item = elem.over_speed;
// 			item.type = 'speeding';
// 			item.vehicle_tag = plate;
// 			item.speed = item.value;
// 			return {
// 				type: item.type,
// 				vehicle: item.vehicle_tag,
// 				info: getAlertText(item, accountId, us, units),
// 				time: getTimeForReport(item.timestamp),
// 				geocode: item.location,
// 				timestamp: item.timestamp,
// 				fuel_consumed: item.fc,
// 				id: item.alert_id
// 			};
// 		});
// 	}
// 	if (alerts.post_trip_freerun_eval) {
// 		obj.freerunCount = alerts.post_trip_freerun_eval.length;
// 		obj.freerunning = alerts.post_trip_freerun_eval.map(function (elem, index) {
// 			var item = elem.freerun;
// 			item.type = 'freerun';
// 			item.vehicle_tag = plate;
// 			obj.total_freerunning_minutes += item.duration;
// 			return {
// 				type: item.type,
// 				vehicle: item.vehicle_tag,
// 				info: getAlertText(item, accountId, us, units),
// 				time: getTimeForReport(item.timestamp),
// 				geocode: item.location,
// 				duration: item.duration,
// 				timestamp: item.timestamp,
// 				id: item.alert_id
// 			};
// 		});
// 	}
// 	if (hard_brake) {
// 		obj.hard_brake_count = hard_brake.length;
// 		obj.hard_braking = hard_brake.map(function (item, index) {
// 			item.type = 'hard_brake';
// 			item.vehicle_tag = plate;
// 			return {
// 				type: item.type,
// 				vehicle: item.vehicle_tag,
// 				info: getAlertText(item, accountId, us, units),
// 				time: getTimeForReport(item.timestamp),
// 				geocode: item.location,
// 				timestamp: item.timestamp,
// 				id: item.alert_id
// 			};
// 		});
// 	}
// 	obj.total_freerunning_minutes = secondsToMinutes(obj.total_freerunning_minutes);
// 	obj.total_idling_minutes = secondsToMinutes(obj.total_idling_minutes);
// 	return obj;
// };
// function getHistoryStatusOfDTC(status) {
// 	if (!status || typeof status !== 'string')
// 		return;

// 	return capitalize(status);
// }

// export function toUIDTC(dtc) {
// 	var dtcs = [];
// 	var max = 0;
// 	if (dtc && dtc.dtcs) {
// 		for (var i = 0; i < dtc.dtcs.length; i++) {
// 			var obj = {
// 				code: dtc.dtcs[i].code,
// 				severity: dtc.dtcs[i].severity,
// 				symptoms: convertStringsToSentenceCase(dtc.dtcs[i].symptoms),
// 				causes: convertStringsToSentenceCase(dtc.dtcs[i].causes),
// 				is_sae_defined: dtc.dtcs[i].is_sae_defined,
// 				is_manufacturer_defined: dtc.dtcs[i].is_manufacturer_defined,
// 				description: toTitleCase(dtc.dtcs[i].description),
// 				system: getShowableString(dtc.dtcs[i].system),
// 				subsystem: getShowableString(dtc.dtcs[i].subsystem),
// 				detail: toTitleCase(dtc.dtcs[i].detail),
// 				fmi: dtc.dtcs[i].fmi,
// 				mid: dtc.dtcs[i].mid,
// 				is_sid: dtc.dtcs[i].is_sid,
// 				dtc_mid: dtc.dtcs[i].dtc_mid,
// 				set_time: dtc.dtcs[i].t ? getTimeForOBDReport(dtc.dtcs[i].t) : undefined,
// 				epoch: dtc.dtcs[i].t,
// 				status: dtc.dtcs[i].status,
// 				time: dtc.dtcs[i].timestamp ? getTimeForOBDReport(dtc.dtcs[i].timestamp) : undefined,
// 				history_status: getHistoryStatusOfDTC(dtc.dtcs[i].status)
// 			};
// 			if (obj.code && obj.code.indexOf('-') > -1) {
// 				var spl = obj.code.split('-');
// 				obj.code = spl[0];
// 				obj.fmi = parseInt(spl[1]);
// 				if (isNaN(obj.fmi))
// 					obj = null;
// 			}
// 			if (obj && obj.severity > max) {
// 				max = obj.severity;
// 			}
// 			if (obj) {
// 				dtcs.push(obj);
// 			}
// 		}
// 	}
// 	var obj = {};
// 	if (dtc.paging) {
// 		obj.paging = {
// 			pnum: dtc.paging.pnum,
// 			total: dtc.paging.total,
// 			psize: dtc.paging.psize,
// 			start: ((dtc.paging.pnum - 1) * dtc.paging.psize) + 1,
// 			end: dtc.paging.pnum * dtc.paging.psize,
// 			pages: Math.ceil(dtc.paging.total / dtc.paging.psize)
// 		};
// 	}
// 	obj.dtcs = dtcs;
// 	obj.health = max;
// 	return obj;
// }
// export let toUIPID = function (pids, pidInfo, protocol, us, units) {
// 	var obj = [];
// 	if (!pids)
// 		return;
// 	for (var i = 0; i < pids.length; i++) {
// 		var p = {
// 			code: pids[i].pid,
// 		};
// 		if (p.code == '185') {
// 			if (pids[i].hasOwnProperty('value')) {
// 				p.value = Math.round((pids[i].value) * 100) / 100;
// 			}
// 			if (pids[i].hasOwnProperty('low')) {
// 				p.low = Math.round((pids[i].low) * 100) / 100;
// 				p.high = Math.round((pids[i].high) * 100) / 100;
// 			}
// 			if (pids[i].hasOwnProperty('avg')) {
// 				p.avg = Math.round((pids[i].avg) * 100) / 100;
// 			}
// 		}
// 		else {
// 			if (pids[i].hasOwnProperty('value')) {
// 				p.value = Math.round(pids[i].value);
// 			}
// 			if (pids[i].hasOwnProperty('low')) {
// 				p.low = Math.round(pids[i].low);
// 				p.high = Math.round(pids[i].high);
// 			}
// 			if (pids[i].hasOwnProperty('avg')) {
// 				p.avg = Math.round(pids[i].avg);
// 			}
// 		}


// 		if (pidInfo.hasOwnProperty(p.code)) {
// 			var description = pidInfo[p.code].description;
// 			var unit = pidInfo[p.code].unit;
// 			if (unit && unit == 'psi') {
// 				p.value = Math.round(Units.Pressure(p.value));
// 				p.low = Math.round(Units.Pressure(pids[i].low));
// 				p.high = Math.round(Units.Pressure(pids[i].high));
// 				p.avg = Math.round(Units.Pressure(pids[i].avg));
// 			}
// 			if (unit && unit == '°F') {
// 				p.value = Math.round(Units.Temperature(p.value));
// 				p.low = Math.round(Units.Temperature(pids[i].low));
// 				p.high = Math.round(Units.Temperature(pids[i].high));
// 				p.avg = Math.round(Units.Temperature(pids[i].avg));
// 			}
// 			if (unit && unit == 'MpGal') {
// 				p.value = Math.round(Units.Distance(p.value)/Units.Fuel(1));
// 				p.low = Math.round(Units.Distance(pids[i].low)/Units.Fuel(1));
// 				p.high = Math.round(Units.Distance(pids[i].high)/Units.Fuel(1));
// 				p.avg = Math.round(Units.Distance(pids[i].avg)/Units.Fuel(1));
// 			}
// 			if (unit && unit == 'miles') {
// 				p.value = Math.round(Units.Distance(p.value));
// 				p.low = Math.round(Units.Distance(pids[i].low));
// 				p.high = Math.round(Units.Distance(pids[i].high));
// 				p.avg = Math.round(Units.Distance(pids[i].avg));
// 			}
// 			if (pidInfo[p.code].protocols && pidInfo[p.code].protocols[protocol]) {
// 				description = pidInfo[p.code].protocols[protocol].description;
// 				unit = pidInfo[p.code].protocols[protocol].unit;
// 				sup = pidInfo[p.code].protocols[protocol].sup;
// 			}
// 			p.description = description;
// 			p.unit = unit;
// 			p.sup = pidInfo[p.code].sup;
// 			if (p.hasOwnProperty('value') || p.hasOwnProperty('low'))
// 				obj.push(p);
// 		}
// 	}
// 	return obj;
// };


// function getIATGraphObject(graph) {
// 	if (!graph || (graph.status && graph.status != '200'))
// 		return null;
// 	if (graph.result.status !== '200')
// 		return null;
// 	var iat_clv_rpm_results = graph.result.iat_rpm_clv;
// 	var iat_ect_rpm_results = graph.result.iat_rpm_ect;

// 	var rpm = [];
// 	var iat = [];
// 	var clv = [];

// 	for (var i = 0; i < iat_clv_rpm_results.length; i++) {
// 		rpm.push(iat_clv_rpm_results[i][0]);
// 		iat.push(Math.round(iat_clv_rpm_results[i][1]));
// 		clv.push(Math.round(iat_clv_rpm_results[i][2]));
// 	}
// 	var iat_clv_rpm = {
// 		rpm: rpm,
// 		iat: iat,
// 		clv: clv
// 	};
// 	rpm = [];
// 	iat = [];
// 	var ect = [];

// 	for (var i = 0; i < iat_ect_rpm_results.length; i++) {
// 		rpm.push(iat_ect_rpm_results[i][0]);
// 		iat.push(Math.round(iat_ect_rpm_results[i][1]));
// 		ect.push(Math.round(iat_ect_rpm_results[i][2]));
// 	}
// 	var iat_ect_rpm = {
// 		rpm: rpm,
// 		iat: iat,
// 		ect: ect
// 	};
// 	return {
// 		iat_clv_rpm: iat_clv_rpm,
// 		iat_ect_rpm: iat_ect_rpm
// 	};
// }
var replaceUnderScore = function (value) {
	if (!value) {
		return;
	}
	return value.replace(/_/g, ' ');
};
var capitalize = function (value, lower) {
	if (!value) {
		return;
	}
	return (lower ? value.toLowerCase() : value).replace(/(?:^|\s)\S/g, function (a) {
		return a.toUpperCase();
	});
};
export var getShowableString = function (value,lower) {
	var str = replaceUnderScore(value);
	if(typeof lower === 'undefined')
		lower = true;
	return capitalize(str, lower);
};

// function getAlternatorGraphObject(graph) {
// 	if (graph.status !== '200')
// 		return null;

// 	var results = graph.voltage_dist;
// 	var rpm = [];
// 	var voltages = [];
// 	var max = 0;
// 	var min = 50;
// 	for (var i = 0; i < results.length; i++) {
// 		rpm.push(results[i][0]);
// 		var val = results[i][1];
// 		if (val > max) {
// 			max = val;
// 		}
// 		if (val < min) {
// 			min = val;
// 		}
// 		voltages.push(results[i][1].toFixed(2));
// 	}
// 	if (graph.temporal_dist)
// 		return {
// 			voltage_dist: graph.voltage_dist,
// 			temporal_dist: graph.temporal_dist,
// 			voltage_rpm: {
// 				rpm: rpm,
// 				voltages: voltages,
// 				range: [min, max]
// 			},
// 			ideal_range: graph.ideal_range,
// 			fault_line: graph.fault_line
// 		};
// 	else
// 		return {
// 			voltage_rpm: {
// 				rpm: rpm,
// 				voltages: voltages,
// 				range: [min, max]
// 			}
// 		};
// }

// function getImapThrottleGraphObject(graph) {
// 	if (graph.status !== '200' && graph.status !== 200)
// 		return null;

// 	var results = graph.x;
// 	var map = [];
// 	var imaps = [];
// 	for (var i = 0; i < results.length; i++) {
// 		map.push(results[i]);
// 		imaps.push(graph.y[i].toFixed(2));
// 	}

// 	return {
// 		imap_throttle: {
// 			map: map,
// 			imaps: imaps
// 		}
// 	};
// }

// function getImapGraphObject(graph) {
// 	if (graph.status !== '200' && graph.status !== 200)
// 		return null;

// 	var results = graph.x;
// 	if (results.length === 0)
// 		return null;
// 	var rpm = [];
// 	var imaps = [];
// 	for (var i = 0; i < results.length; i++) {
// 		rpm.push(results[i]);
// 		imaps.push(graph.y[i].toFixed(2));
// 	}

// 	return {
// 		imap_rpm: {
// 			rpm: rpm,
// 			imaps: imaps
// 		}
// 	};
// }

// function getTheECTLoadGrapgObject(graph, range) {
// 	if (graph.status !== '200' && graph.status !== 200)
// 		return null;

// 	var results = graph.x;
// 	var clv = [];
// 	var ect = [];
// 	var data = [];
// 	for (var i = 0; i < results.length; i++) {
// 		var obj = {
// 			x: Math.round(results[i]),
// 			y: graph.y[i].toFixed(2),
// 		};
// 		if (graph.y1 && graph.y1[i]) {
// 			obj.time = getTimeForReport(graph.y1[i]);
// 			obj.timestamp = graph.y1[i];
// 		}
// 		data.push(obj);
// 	}

// 	return {
// 		ect_clv: [{
// 			key: 'ect',
// 			values: data
// 		}],
// 		ideal_range: range || graph.optimal_range
// 	};
// }

// function getFuelTrimGraphObject(graph) {

// 	if (!graph)
// 		return null;

// 	// if(graph.result.status !== "200")
// 	//     return null;

// 	var results = graph;
// 	var rpm = [];
// 	var ft = [];
// 	var clv = [];

// 	if (results && results[0]) {
// 		for (var i = 0; i < results.length; i++) {
// 			rpm.push(results[i][0]);
// 			ft.push(results[i][1]);
// 			clv.push(Math.round(results[i][2]));
// 		}
// 	}
// 	else if (results && results.totalTrimPlotData && results.totalTrimPlotData.totalTrimCoordinates) {
// 		for (var j = 0; j < results.totalTrimPlotData.totalTrimCoordinates.length; j++) {
// 			rpm.push(results.totalTrimPlotData.totalTrimCoordinates[j][0]);
// 			ft.push(results.totalTrimPlotData.totalTrimCoordinates[j][1]);
// 			clv.push(Math.round(results.totalTrimPlotData.totalTrimCoordinates[j][2]));
// 		}
// 	}


// 	return {
// 		ft_clv_rpm: {
// 			rpm: rpm,
// 			ft: ft,
// 			clv: clv
// 		}
// 	};
// }

// function getOBDHealthObject(graph) {

// 	if (!graph)
// 		return null;
// 	var results = graph;
// 	var packet_time = results.packet_time;
// 	var trip_time = Math.floor(results.trip_time / 60);
// 	var total_packets = results.total_packets;
// 	var ideal_packet_count = results.ideal_packet_count;
// 	var differences = [];
// 	var d = results.diff;

// 	for (var i = 0; i < d.length; i++) {
// 		var diff = d[i];
// 		differences.push({
// 			end_time: getTimeForReport(diff.end_last),
// 			start_time: getTimeForReport(diff.start_next),
// 			diff: Math.ceil(diff.diff / 1000)
// 		});
// 	}

// 	return {
// 		packet_time: packet_time,
// 		trip_time: trip_time,
// 		total_packets: total_packets,
// 		ideal_packet_count: ideal_packet_count,
// 		differences: differences
// 	};
// }
// export let toUINonWorking = function (obj) {
// 	return obj.map(function (item) {
// 		if (item.name && item.name != '')
// 			item.name = toTitleCase(replaceUnderScore(item.name));
// 		return item;
// 	});
// };
// export let toUIGraphs = function (graphs) {
// 	var values = {};
// 	if (graphs.status.code !== '200')
// 		return null;
// 	if (graphs.trip_id) {
// 		values.trip_id = graphs.trip_id;
// 	}
// 	var g = graphs.graphs;
// 	for (var i = 0; i < g.length; i++) {
// 		var type = g[i].algorithm;
// 		var obj;
// 		switch (type.toLowerCase()) {
// 		case 'iat':
// 			obj = getIATGraphObject(g[i]);
// 			break;
// 		case 'alternator':
// 			console.log(g[i].result);
// 			obj = getAlternatorGraphObject(g[i].result);
// 			break;
// 		case 'fuel_trim':
// 			obj = getFuelTrimGraphObject(g[i].result);
// 			break;
// 		case 'imap':
// 			obj = getImapGraphObject(g[i].result);
// 			break;
// 		case 'ect':
// 			obj = getTheECTLoadGrapgObject(g[i].result);
// 			break;
// 		case 'imap_throttle':
// 			obj = getImapThrottleGraphObject(g[i].result);
// 			break;
// 		case 'obd_data_health':
// 			obj = getOBDHealthObject(g[i].result);
// 			break;
// 		case 'fuel_trim_analysis':
// 			obj = g[i].result;
// 			break;
// 		case 'pid_summary':
// 			obj = g[i].result;
// 			break;
// 		}
// 		values[type] = obj;
// 	}
// 	return values;
// };


// export let toUIAlgosAndGraphs = function (health_graps_algos, us, units) {
// 	var values = {};
// 	if (!health_graps_algos.status || health_graps_algos.status.code != '200' ||
//         !health_graps_algos.health_output)
// 		return null;
// 	// if (health_graps_algostrip_id) {
// 	//     values.trip_id = health_graps_algostrip_id;
// 	// }
// 	var returnObj;
// 	_.forEach(health_graps_algos.health_output, function (value, algo) {
// 		if (!returnObj) {
// 			returnObj = {};
// 		}
// 		returnObj[algo] = {};
// 		if (value.time) {
// 			returnObj[algo].time = getTimeForOBDReport(value.time);
// 		}
// 		if (value.trip_id) {
// 			returnObj[algo].trip_id = value.trip_id;
// 		}
// 		switch (algo) {
// 		case 'alternator':
// 			value.graphs = value.graphs || value.graph;
// 			if (value.hasOwnProperty('graphs')) {
// 				returnObj[algo].graphs = getAlternatorGraphObject(value.graphs);
// 			}
// 			if (value.hasOwnProperty('algos')) {
// 				returnObj[algo].algos = getAlternatorAlgoObject(value.algos);
// 			}
// 			break;

// 		case 'iat':
// 			if (value.hasOwnProperty('graphs')) {
// 				value.graphs = value.graphs || value.graph;
// 				returnObj[algo].graphs = getIATGraphObject(value.graphs);
// 			}
// 			if (value.hasOwnProperty('algos')) {
// 				//function to be written
// 				// returnObj[algo].algos = (value.graphs);
// 			}
// 			break;

// 		case 'fuel_trim':
// 			if (value.hasOwnProperty('graphs')) {
// 				value.graphs = value.graphs || value.graph;
// 				returnObj[algo].graphs = getFuelTrimGraphObject(value.graphs);
// 			}
// 			if (value.hasOwnProperty('algos')) {
// 				returnObj[algo].algos = getFuelTrimAlgoObject(value.algos);
// 			}
// 			break;
// 		case 'fuel_trim_analysis':
// 			if (value.hasOwnProperty('graphs')) {
// 				value.graphs = value.graphs || value.graph;
// 				returnObj[algo].graphs = value.graphs;
// 			}
// 			if (value.hasOwnProperty('algos')) {
// 				returnObj[algo].algos = getFuelTrimAnalysisAlgoObject(value.algos);
// 				returnObj[algo].status = value.status;
// 			}
// 			break;
// 		case 'imap':
// 			if (value.hasOwnProperty('graphs')) {
// 				value.graphs = value.graphs || value.graph;
// 				returnObj[algo].graphs = getImapGraphObject(value.graphs);
// 			}
// 			if (value.hasOwnProperty('algos')) {
// 				returnObj[algo].algos = getImapAlgoObject(value.algos);
// 			}
// 			break;
// 		case 'aid':
// 			if (value.hasOwnProperty('graphs')) {
// 				value.graphs = value.graphs || value.graph;
// 				returnObj[algo].graphs = value.graphs;
// 			}
// 			if (value.hasOwnProperty('algos')) {
// 				returnObj[algo].algos = getImapAlgoObject(value.algos);
// 			}
// 			break;
// 		case 'ect':
// 			if (value.hasOwnProperty('graphs')) {
// 				value.graphs = value.graphs || value.graph;
// 				var range;
// 				if (value.hasOwnProperty('algos')) {
// 					if (value.algos && value.algos.values && value.algos.values.description && value.algos.values.description.ideal_range)
// 						range = value.algos.values.description.ideal_range;
// 				}
// 				returnObj[algo].graphs = getTheECTLoadGrapgObject(value.graphs, range);
// 			}
// 			if (value.hasOwnProperty('algos')) {
// 				returnObj[algo].algos = getECTAlgoObject(value.algos, us, units);
// 			}
// 			break;

// 		case 'imap_throttle':
// 			if (value.hasOwnProperty('graphs')) {
// 				value.graphs = value.graphs || value.graph;
// 				returnObj[algo].graphs = getImapThrottleGraphObject(value.graphs);
// 			}
// 			if (value.hasOwnProperty('algos')) {
// 				//TODO: algo needs to be written
// 				// returnObj[algo].algos = getAlternatorAlgoObject(value.graphs);
// 			}
// 			break;

// 		case 'obd_data_health':
// 			if (value.hasOwnProperty('graphs')) {
// 				value.graphs = value.graphs || value.graph;
// 				returnObj[algo].graphs = getOBDHealthObject(value.graphs);
// 			}
// 			if (value.hasOwnProperty('algos')) {
// 				// No algo for this currently
// 				// returnObj[algo].algos = getAlternatorAlgoObject(value.graphs);
// 			}
// 			break;

// 		}
// 		if (algo == 'pid_summary' && value.graphs && value.graphs.pids) {
// 			returnObj[algo].graphs = value.graphs.pids;
// 		}
// 	});
// 	return returnObj;
// };
// function alertObjectHandle(algo) {
// 	if (algo.status && algo.status != '200')
// 		return;
// 	var description = algo.description;
// 	var result = algo.result_value ? algo.result_value : algo.values;

// 	var value, temporalValue, rpmValue;
// 	var range, temporalRange, rpmRange;
// 	var unit = 'V';

// 	if (result && result.status === 204)
// 		return;
// 	if (result) {
// 		var rawRpmRange = result.voltageVsRpm ? result.voltageVsRpm.acceptable_range : null;
// 		var rawTimeRange = result.temporalAnalysis ? result.temporalAnalysis.acceptable_range : null;
// 		unit = result.description.unit;
// 		if (result.description.curr_reading.value != '' && result.description.curr_reading.value != null && result.description.curr_reading.value != undefined)
// 			value = result.description.curr_reading.value.toFixed(2) + ' ' + unit;
// 		range = result.description.ideal_range.join(' - ');
// 		range = range + ' ' + unit;

// 		if (result.temporalAnalysis && result.temporalAnalysis.last_recorded_voltage && result.temporalAnalysis.acceptable_range) {
// 			result.temporalAnalysis.acceptable_range.forEach(function (item, index) {
// 				result.temporalAnalysis.acceptable_range[index] = item.toFixed(2);
// 			});
// 			temporalValue = result.temporalAnalysis.last_recorded_voltage.toFixed(2) + ' ' + unit;
// 			temporalRange = result.temporalAnalysis.acceptable_range.join(' - ') + ' ' + unit;

// 		}
// 		if (result.voltageVsRpm && result.voltageVsRpm.current_range && result.voltageVsRpm.acceptable_range) {
// 			result.voltageVsRpm.current_range.forEach(function (item, index) {
// 				result.voltageVsRpm.current_range[index] = item.toFixed(2);
// 			});
// 			result.voltageVsRpm.acceptable_range.forEach(function (item, index) {
// 				result.voltageVsRpm.acceptable_range[index] = item.toFixed(2);
// 			});
// 			rpmValue = result.voltageVsRpm.current_range.join(' - ') + ' ' + unit;
// 			rpmRange = result.voltageVsRpm.acceptable_range.join(' - ') + ' ' + unit;
// 		}
// 	} else if (description) {
// 		for (var i = 0; i < description.length; i++) {
// 			var split = description.split(':');
// 			if (split[0].indexOf('ideal') >= 0 || split[0].indexOf('Ideal') >= 0) {
// 				range = split[1];
// 			} else {
// 				value = split[1];
// 			}
// 		}
// 	}
// 	var obj = {
// 		value: value,
// 		range: range,
// 		temporalRange: temporalRange,
// 		temporalValue: temporalValue,
// 		rpmRange: rpmRange,
// 		rpmValue: rpmValue,
// 		health: algo.hasOwnProperty('health_status') ? algo.health_status : algo.health,
// 		unit: unit,
// 		causes: convertStringsToSentenceCase(algo.causes),
// 		symptoms: convertStringsToSentenceCase(algo.symptoms),
// 		alert_msg: algo.alert ? toSentenceCase(algo.alert) : toSentenceCase(algo.alert_msg),
// 		rawRpmRange: rawRpmRange,
// 		rawTimeRange: rawTimeRange
// 	};
// 	return obj;
// }
// function getAlternatorAlgoObject(algo) {
// 	if (!algo) {
// 		return;
// 	}

// 	if (!algo.hasOwnProperty('health_status') && !algo.hasOwnProperty('health') && (algo.alert || algo.alert_msg)) {
// 		algo.alert = algo.alert || algo.alert_msg;
// 		return alertObjectHandle(JSON.parse(algo.alert));
// 	}
// 	else {
// 		return alertObjectHandle(algo);
// 	}
// }

// function getFuelTrimAlgoObject(algo) {

// 	if (!algo) {
// 		return;
// 	}
// 	var result = algo.result_value ? algo.result_value : algo.values;
// 	if (result && result.description) {
// 		var description = algo.description;
// 		// var result = algo.result_value;

// 		if (!result)
// 			return;

// 		// if (result.status !== 200)
// 		//     return;

// 		var value;
// 		var range;
// 		var unit = '%';

// 		if (result) {
// 			unit = result.description.unit;
// 			value = result.description.curr_reading.value.toFixed(2) + ' ' + unit;
// 			range = result.description.ideal_range.join(' to ');
// 			range = range + ' ' + unit;

// 		} else if (description) {
// 			for (var i = 0; i < description.length; i++) {
// 				var split = description.split(':');
// 				if (split[0].indexOf('ideal') >= 0 || split[0].indexOf('Ideal') >= 0) {
// 					range = split[1];
// 				} else {
// 					value = split[1];
// 				}
// 			}
// 		}
// 		return {
// 			value: value,
// 			range: range,
// 			health: algo.hasOwnProperty('health_status') ? algo.health_status : algo.health,
// 			unit: unit,
// 			causes: convertStringsToSentenceCase(algo.causes),
// 			symptoms: convertStringsToSentenceCase(algo.symptoms),
// 			alert_msg: algo.alert ? algo.alert : algo.alert_msg
// 		};
// 	}

// 	if (algo.alert_msg) {
// 		var value = isJson(algo.alert_msg);
// 		if (!value)
// 			return null;
// 		if (value)
// 			value = JSON.parse(algo.alert_msg);
// 		var result = value[0];

// 		if (!result)
// 			return;

// 		var value;
// 		var range;
// 		var unit = '%';

// 		if (result) {
// 			unit = result.values.description.unit;
// 			value = result.values.description.curr_reading.value.toFixed(2) + ' ' + unit;
// 			range = result.values.description.ideal_range.join(' to ');
// 			range = range + ' ' + unit;

// 		}
// 		return {
// 			value: value,
// 			range: range,
// 			health: result.health_status,
// 			unit: unit,
// 			//causes: convertStringsToSentenceCase(algo.causes),
// 			//symptoms: convertStringsToSentenceCase(algo.symptoms),
// 			alert_message: result.alert
// 		};
// 	}



// }
// function getFuelTrimAnalysisAlgoObject(a) {
// 	if (!a)
// 		return;
// 	if (a.alertObject) {
// 		a.alertObject.causes = convertStringsToSentenceCase(a.alertObject.causes);
// 		a.alertObject.symptoms = convertStringsToSentenceCase(a.alertObject.symptoms);
// 		if (a.alertObject.alert_msg)
// 			a.alertObject.alert_msg = toSentenceCase(a.alertObject.alert_msg);
// 		if (a.alertObject.alert)
// 			a.alertObject.alert = toSentenceCase(a.alertObject.alert);
// 	}
// 	else {
// 		a.causes = convertStringsToSentenceCase(a.causes);
// 		a.symptoms = convertStringsToSentenceCase(a.symptoms);
// 		if (a.alert_msg)
// 			a.alert_msg = toSentenceCase(a.alert_msg);
// 		if (a.alert)
// 			a.alert = toSentenceCase(a.alert);
// 	}
// 	return a;
// }
// function isJson(str) {
// 	try {
// 		JSON.parse(str);
// 	} catch (e) {
// 		return false;
// 	}
// 	return true;
// }

// function getImapAlgoObject(algo) {
// 	if (!algo) {
// 		return;
// 	}
// 	var description = algo.description;
// 	var result = algo.result_value ? algo.result_value : algo.values;

// 	var value_wide_open;
// 	var value_idle;
// 	var range_wide_open;
// 	var range_idle;
// 	var unit = 'KPa';

// 	if (result) {
// 		unit = result.description.unit;
// 		value_wide_open = result.description.curr_reading_wide_open_throttle.value.toFixed(0) + ' ' + unit;
// 		value_idle = result.description.curr_reading_idle.value.toFixed(0) + ' ' + unit;

// 		range_wide_open = result.description.ideal_range_wide_open_throttle.join(' to ');
// 		range_wide_open = range_wide_open + ' ' + unit;

// 		range_idle = result.description.ideal_range_idle.join(' to ');
// 		range_idle = range_idle + ' ' + unit;

// 	} else if (description) {
// 		for (var i = 0; i < description.length; i++) {
// 			var split = description.split(':');
// 			if (split[0].indexOf('ideal') >= 0 || split[0].indexOf('Ideal') >= 0) {
// 				range = split[1];
// 			} else {
// 				value = split[1];
// 			}
// 		}
// 	}
// 	return {
// 		value_wide_open: value_wide_open,
// 		value_idle: value_idle,

// 		range_wide_open: range_wide_open,
// 		range_idle: range_idle,
// 		health: algo.hasOwnProperty('health_status') ? algo.health_status : algo.health,
// 		unit: unit,
// 		causes: convertStringsToSentenceCase(algo.causes),
// 		symptoms: convertStringsToSentenceCase(algo.symptoms),
// 		alert_msg: algo.alert ? algo.alert : algo.alert_msg
// 	};
// }

// function getECTAlgoObject(algo, us, units) {
// 	if (!algo || (algo.status && algo.status != 200)) {
// 		return;
// 	}
// 	var description = algo.description;
// 	var result = algo.result_value ? algo.result_value : algo.values;
// 	// var result = algo.result_value;       
// 	if (!result || result.status === 204)
// 		return;

// 	var value;
// 	var range;
// 	var unit = Units.config.temperature.unit.symbol;
// 	if (result) {
// 		unit = result.description.unit;
// 		unit = Units.config.temperature.unit.symbol;
// 		value = Units.Temperature(result.description.curr_reading.value) + ' ' + unit;
// 		range = result.description.ideal_range.map(function (item) {
// 			return Units.Temperature(item);
// 		}).join(' - ');
// 		range += ' ' + unit;

// 	} else if (description) {
// 		for (var i = 0; i < description.length; i++) {
// 			var split = description[i].split(':');
// 			if (split[0].indexOf('ideal') >= 0 || split[0].indexOf('Ideal') >= 0) {
// 				range = split[1];
// 			} else {
// 				value = split[1];
// 			}
// 		}
// 	}
// 	return {
// 		value: value,
// 		range: range,
// 		health: algo.hasOwnProperty('health_status') ? algo.health_status : algo.health,
// 		unit: unit,
// 		causes: convertStringsToSentenceCase(algo.causes),
// 		symptoms: convertStringsToSentenceCase(algo.symptoms),
// 		alert_msg: algo.alert ? toSentenceCase(algo.alert) : toSentenceCase(algo.alert_msg)
// 	};
// }

// export let toUIAlgo = function (algos, us, units) {
// 	var values = {};
// 	var a = algos.algos;
// 	if (algos.trip_id) {
// 		values.trip_id = algos.trip_id;
// 	}
// 	for (var i = 0; i < a.length; i++) {
// 		var type = a[i].algorithm;
// 		var obj;
// 		switch (type.toLowerCase()) {
// 		//    case 'iat':
// 		//     obj = getIATGraphObject(g[i]);
// 		//     break;
// 		case 'alternator':
// 			obj = getAlternatorAlgoObject(a[i]);
// 			break;
// 		case 'fuel_trim':
// 			obj = getFuelTrimAlgoObject(a[i]);
// 			break;
// 		case 'fuel_trim_analysis':
// 			obj = getFuelTrimAnalysisAlgoObject(a[i]);
// 			break;
// 		case 'imap':
// 			obj = getImapAlgoObject(a[i]);
// 			break;
// 		case 'ect':
// 			obj = getECTAlgoObject(a[i], us, units);
// 			break;
//             // case 'imap_throttle':
//             // obj = getImapThrottleGraphObject(g[i]);
//             // break;
// 		}
// 		values[type] = obj;
// 	}
// 	return values;
// };

// function getTripDistance(tr, protocol) {

// 	if (tr.distance)
// 		return tr.distance;
// 	if (tr.distance == null)
// 		return 0;
// 	if (protocol === 'AUTO, ISO 15765-4') {
// 		return tr.distance_obd_speed / 1000;
// 	}


// 	//    if(protocol.toLowerCase().indexOf('j1939') > -1){
// 	//        return tr.distance;
// 	//    }
// 	if (tr.distance_google && tr.distance_google > 1000) {
// 		return tr.distance_google / 1000;
// 	}

// 	//    if(tr.distance)
// 	//         return tr.distance;
// 	// //    else if(tr.distance_google)
// 	// //         return tr.distance_google / 1000; 
// 	else if (tr.distance_obd_speed)
// 		return tr.distance_obd_speed;
// 	else
// 		return 0;

// }

// function getTripMileage(tr, protocol) {
// 	var dist = getTripDistance(tr, protocol);
// 	if (!dist) {
// 		return 0;
// 	}
// 	var mileage = dist / tr.fuel_consumed;
// 	return mileage;
// }
// function getUiHealth(health) {
// 	var text = '';
// 	var healthClass = 'health-';

// 	switch (health) {
// 	case 0:
// 		text = 'Good';
// 		healthClass += 'good';
// 		break;
// 	case 1:
// 		text = 'Minor Issue';
// 		healthClass += 'minor';
// 		break;
// 	case 2:
// 		text = 'Major Issue';
// 		healthClass += 'major';
// 		break;
// 	case 3:
// 		text = 'Critical Issue';
// 		healthClass += 'critical';
// 		break;
// 	default:
// 		text = 'Good';
// 		healthClass += 'good';
// 		break;
// 	}
// 	return {
// 		text: text,
// 		healthClass: healthClass,
// 		val: health
// 	};
// }
// export let toUITrip = function (trips, protocol, accountId, appconfig) {
// 	var t = [];
// 	var timeDiff = 1000 * 60 * 5;
// 	if (accountId && accountId == '454970251768107008')
// 		var timeDiff = 1000 * 60 * 10;
// 	// check for tata steel account
// 	if (appconfig.portalEnvironment == 'blue')
// 		var timeDiff = 1000 * 60 * 15;

// 	var max = 0;
// 	for (var i = 0; i < trips.length; i++) {
// 		var tr = trips[i];
// 		var diff = tr.end_time - tr.start_time;


// 		if (diff >= timeDiff) {
// 			var obj = {
// 				start: getTimeForReport(tr.start_time),
// 				start_time: tr.start_time,
// 				end: getTimeForReport(tr.end_time),
// 				end_time: tr.end_time,
// 				duration: getTotalTravelTime(tr.start_time, tr.end_time),
// 				distance: getTripDistance(tr, protocol),
// 				distance_obd: tr.distance_obd_speed / 1000,
// 				distance_gps: tr.distance,
// 				distance_google: tr.distance_google / 1000,
// 				fuel_consumed: tr.fuel_consumed,
// 				mileage: getTripMileage(tr, protocol),
// 				id: tr.id,
// 				raw_fuel_consumed: tr.raw_fuel_consumed,
// 				health: getUiHealth(tr.health)
// 			};
// 			t.push(obj);
// 		}
// 	}
// 	t.max = max;
// 	return t;
// };

// export let toUIDevice = function (idevices) {
// 	var devices = [];
// 	var returnObj = {};
// 	for (var i = 0; i < idevices.idevices.length; i++) {
// 		var idev = idevices.idevices[i];
// 		var obj = {
// 			imei: idev.imei,
// 			id: idev.id,
// 			tag: idev.tag,
// 			isenabled: idev.isenabled,
// 			state: idev.state,
// 			status: idev.status,
// 			type: idev.type,
// 			protocol: idev.communication_protocol,
// 			tv: idev.tv,
// 			sdk: idev.sdk,
// 			ov: idev.ov,
// 			l2: idev.l2,
// 			tags: idev.tags
// 		};
// 		devices.push(obj);
// 	}
// 	returnObj.paging = {
// 		pnum: idevices.paging.pnum,
// 		total: idevices.paging.total,
// 		psize: idevices.paging.psize,
// 		start: ((idevices.paging.pnum - 1) * idevices.paging.psize) + 1,
// 		end: idevices.paging.pnum * idevices.paging.psize,
// 		pages: Math.ceil(idevices.paging.total / idevices.paging.psize)
// 	};
// 	returnObj.idevices = devices;
// 	if (returnObj.paging.end > returnObj.paging.total)
// 		returnObj.paging.end = returnObj.paging.total;

// 	return returnObj;

// };

function convertStringsToTitleCase(strings) {
	if (!strings) {
		return undefined;
	}
	var str = [];
	for (var i = 0; i < strings.length; i++) {
		var s = toTitleCase(strings[i]);
		str.push(s);
	}
	return str;
}

function convertStringsToSentenceCase(strings) {
	if (!strings) {
		return undefined;
	}
	var str = [];
	for (var i = 0; i < strings.length; i++) {
		var s = toSentenceCase(strings[i]);
		str.push(s);
	}
	return str;
}

export function toTitleCase(str, removeSlashN) {
	if (!str) {
		return undefined;
	}
	str = str.trim();
	if (str.toLowerCase() === 'cng') {
		return str.toUpperCase();
	}
	if(!removeSlashN)
		return str.replace(/\w+/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	else
		return str.replace(/\w+/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}).replace( /\n/g, " ");
}

export function toSentenceCase(strval) {
	if (!strval)
		return;
	strval = strval.trim();
	var newstrs = strval.split('.');
	var finalstr = '';
	//alert(strval);
	for (var i = 0; i < newstrs.length; i++)
		finalstr = finalstr + '.' + newstrs[i].substr(0, 1).toUpperCase() + newstrs[i].substr(1);
	return finalstr.substr(1);
}
export let toUIHealthSpecs = function (array) {
	return array.map(function (item) {
		item.manufacturer = toTitleCase(replaceUnderScore(item.manufacturer));
		item.model = toTitleCase(replaceUnderScore(item.model));
		return item;
	});
};
// export let toUISpecification = function (specification) {
// 	var specs = {};
// 	specs.engine_type = specification.engine_type;
// 	specs.cc = specification.cc;
// 	specs.fuel = {
// 		type: toTitleCase(specification.fuel.type)
// 	};
// 	specs.peak_hp = specification.peak_hp;
// 	specs.peak_hp_rpm = specification.peak_hp_rpm;
// 	specs.peak_torque = specification.peak_torque;
// 	specs.peak_torque_rpm = specification.peak_torque_rpm;
// 	specs.has_turbo = specification.has_turbo;
// 	specs.mileage = specification.mileage;
// 	specs.model = toTitleCase(specification.model);
// 	specs.manufacturer = getShowableString(specification.manufacturer);
// 	if (specification.peak_torque_rpm_array)
// 		specs.peak_torque_rpm_array = specification.peak_torque_rpm_array.join('-');
// 	return specs;
// };

// export let toUISpecificationNew = function (s) {
// 	for (var d = 0; d < s.length; d++) {
// 		var specs = s[d];
// 		s[d].display_manufacturer = getShowableString(specs.manufacturer);
// 		s[d].display_model = toTitleCase(specs.model);
// 		if (specs && specs.code_versions) {
// 			specs.code_versions.map(function (o) {
// 				if (o.name == 'L1')
// 					return specs.L1 = o;
// 				if (o.name == 'L2')
// 					return specs.L2 = o;
// 				if (o.name == 'M66' || o.name == 'm66')
// 					return specs.m66 = o;
// 			});
// 		}
// 	}
// 	return s;
// };

// export let toUIUser = function (user) {
// 	return user;
// };

// export var toUIGeofenceObj = function (geofence_resp) {
// 	var obj = {};
// 	obj.id = geofence_resp.id;
// 	obj.name = geofence_resp.tag;
// 	if (geofence_resp.hasOwnProperty('center')) {
// 		obj.center = {
// 			lat: geofence_resp.center.latitude,
// 			lng: geofence_resp.center.longitude
// 		};
// 		obj.radius = geofence_resp.radius;
// 	} else if (geofence_resp.hasOwnProperty('points') && geofence_resp.points !== '' && geofence_resp.points != []) {
// 		var geo_points = [];
// 		if (geofence_resp.points.coordinates)
// 			geofence_resp.points.coordinates.forEach(function (pt) {
// 				geo_points.push({
// 					lat: pt.latitude,
// 					lng: pt.longitude
// 				});
// 			});
// 		else
// 			geofence_resp.points.forEach(function (pt) {
// 				geo_points.push({
// 					lat: pt.latitude,
// 					lng: pt.longitude
// 				});
// 			});
// 		obj.points = geo_points;
// 	}
// 	obj.calc_distance = geofence_resp.calc_distance ? true : false;

// 	return obj;
// };

// export let toUIGeofenceList = function (geofences_data) {
// 	var obj = {};
// 	var list = [];
// 	var gf_list = geofences_data.gf;
// 	if (gf_list) {
// 		gf_list.forEach(function (gf) {
// 			var gf_obj = toUIGeofenceObj(gf);
// 			list.push(gf_obj);
// 		});
// 		obj.list = list;
// 		obj.paging = geofences_data.paging;
// 	}

// 	return obj;
// };

// export let toUIAccount = function (accounts) {
// 	return accounts;
// };
// export let toUIMileageGraph = function (mileage_data, vehicles_data, target, sort) {
// 	var mileages = [];
// 	var vehicles = [];
// 	var distances = [];
// 	var fuelConsumed = [];
// 	var avgs = [];
// 	var targets = [];
// 	if (mileage_data.avg && mileage_data.v) {
// 		var avg = mileage_data.avg.toFixed(2);
// 		var vehiclesData = mileage_data.v;

// 		for (var i = 0; i < vehicles_data.length; i++) {
// 			var a = vehicles_data[i];

// 			var filtered_vehicle_mileage_info = _.filter(vehiclesData, {
// 				vid: a.id
// 			});

// 			if (filtered_vehicle_mileage_info.length && filtered_vehicle_mileage_info[0].mileage) {
// 				a.current_mileage = parseFloat((filtered_vehicle_mileage_info[0].mileage).toFixed(2));
// 				a.current_distance = filtered_vehicle_mileage_info[0].distance;
// 				a.current_avg = parseFloat(avg);
// 				a.current_fuel = filtered_vehicle_mileage_info[0].fuel;
// 				a.current_target = target;
// 			} else {
// 				a.current_mileage = 0;
// 				a.current_distance = 0;
// 				a.current_avg = parseFloat(avg);
// 				a.current_target = parseFloat(target);
// 				a.current_fuel = 0;
// 			}

// 			if (filtered_vehicle_mileage_info.length && filtered_vehicle_mileage_info[0].mileage) {
// 				a.current_mileage = parseFloat((filtered_vehicle_mileage_info[0].mileage).toFixed(2));
// 				a.current_distance = filtered_vehicle_mileage_info[0].distance;
// 				a.current_avg = parseFloat(avg);
// 				a.current_fuel = filtered_vehicle_mileage_info[0].fuel;
// 				a.current_target = target;
// 			} else {
// 				a.current_mileage = 0;
// 				a.current_distance = 0;
// 				a.current_avg = parseFloat(avg);
// 				a.current_target = parseFloat(target);
// 				a.current_fuel = 0;
// 			}
// 		}

// 		if (sort == 'desc') {
// 			var sorted_vehicle_by_mileage = _.orderBy(vehicles_data, ['current_mileage',], ['desc']);
// 		}
// 		else
// 			var sorted_vehicle_by_mileage = _.orderBy(vehicles_data, ['current_mileage',], ['asc']);

// 		mileages = _.map(sorted_vehicle_by_mileage, 'current_mileage');
// 		vehicles = _.map(sorted_vehicle_by_mileage, 'tagged');
// 		distances = _.map(sorted_vehicle_by_mileage, 'current_distance');
// 		avgs = _.map(sorted_vehicle_by_mileage, 'current_avg');
// 		targets = _.map(sorted_vehicle_by_mileage, 'current_target');

// 		/*
//           for (var i = 0; i < vehicles_data.length; i++) {
//               var element = vehicles_data[i];
              
//               var filtered_array = _.filter(vehiclesData, {
//                    vid: element.id
//                });
//                if(filtered_array.length){
//                    mileages.push((filtered_array[0].mileage).toFixed(2));
//                    distances.push(filtered_array[0].distance);
//                    avgs.push(avg);
//                    vehicles.push(element.tagged);
//                    targets.push(target);
//                }else{
//                    mileages.push(0);
//                    distances.push(0);
//                    avgs.push(avg);
//                    vehicles.push(element.tagged);
//                    targets.push(target);
//                }
//           }   
//           */

// 	}
// 	var obj =
//         {
//         	mileages: mileages,
//         	labels: vehicles,
//         	distances: distances,
//         	avgs: avgs,
//         	targets: targets,
//         	sortedvehicles: sorted_vehicle_by_mileage
//         };
// 	return obj;
// };


// export let toUIdlingGraph = function (mileage_data, vehicles_data, appconfig, fuel_type, us_unit, kwd, unitsSvc) {
// 	var vehiclesData = mileage_data.v;
// 	var total_fuel = [];
// 	var total_time = [];
// 	var vehicles = [];
// 	var count = [];
// 	var price = 0;
// 	final_fuel_count = 0;
// 	final_time_count = 0;
// 	var idling_data = [];
// 	var total_diesel_loss = [], total_cng_loss = [], total_petrol_loss = [], total_gasoline_loss = [];
// 	var total_diesel_quantity = [], total_cng_quantity = [], total_petrol_quantity = [], total_gasoline_quantity = [];
// 	var specifications = appconfig.account_config.specs.specs;
// 	var diesel_vehicle_count = 0, cng_vehicle_count = 0, petrol_vehicle_count = 0;
// 	vehicles_data.map(function (item) {

// 		if (item && item.spec_id) {

// 			current_spec_info = _.filter(specifications, {
// 				id: item.spec_id
// 			});

// 			if (current_spec_info && current_spec_info.length > 0) {
// 				item.fuel_type = current_spec_info[0].fuel.type;
// 			}
// 		}

// 	});
// 	var fuel_obj = [];
// 	if (!vehicles_data || vehicles_data == undefined) {
// 		return ({
// 			idledata: [total_time, total_fuel],
// 			idlelabel: vehicles,
// 			final_fuel_count: final_fuel_count,
// 			final_time_count: final_time_count,
// 			total_diesel_loss: 0,
// 			total_cng_loss: 0,
// 			total_petrol_loss: 0,
// 			total_gasoline_loss: 0,
// 			total_diesel_quantity: 0,
// 			total_cng_quantity: 0,
// 			total_petrol_quantity: 0,
// 			total_gasoline_quantity: 0,
// 			fuel_obj: fuel_obj
// 		});
// 	}

// 	for (var i = 0; i < vehicles_data.length; i++) {

// 		var element = vehicles_data[i];
// 		vehicles.push(element.tagged);
// 		idling_data = _.filter(mileage_data.data, {
// 			vid: vehicles_data[i].id
// 		});

// 		if (idling_data.length) {

// 			if (vehicles_data[i].fuel_type == 'diesel' || vehicles_data[i].fuel_type == 'DIESEL') {
// 				diesel_vehicle_count++;
// 				price = appconfig.diesel_price;
// 				fuel = idling_data[0].fuel;
// 				if (us_unit) {
// 					price = unitsSvc.toDollars(appconfig.diesel_price) * 3.78;
// 					fuel = unitsSvc.toGallons(fuel);
// 				}
// 				else if (kwd) {
// 					price = appconfig.diesel_price_kwd;
// 				}
// 				fuel_obj.push(fuel);
// 				total_diesel_loss.push((fuel * price).toFixed(2));
// 				total_diesel_quantity.push(fuel);

// 			} else if (vehicles_data[i].fuel_type == 'CNG' || vehicles_data[i].fuel_type == 'cng') {
// 				cng_vehicle_count++;
// 				price = appconfig.cng_price;
// 				fuel = idling_data[0].fuel;
// 				if (us_unit) {
// 					price = unitsSvc.toDollars(appconfig.cng_price);
// 				}
// 				fuel_obj.push(fuel);
// 				total_cng_loss.push((fuel * price).toFixed(2));
// 				total_cng_quantity.push(fuel);
// 			}
// 			else if (vehicles_data[i].fuel_type == 'petrol' || vehicles_data[i].fuel_type == 'PETROL') {
// 				petrol_vehicle_count++;
// 				price = appconfig.petrol_price;
// 				fuel = idling_data[0].fuel;
// 				if (us_unit) {
// 					price = appconfig.gasoline_price;
// 					fuel = unitsSvc.toGallons(fuel);
// 				} else if (kwd) {
// 					price = appconfig.petrol_price_kwd;
// 				}
// 				fuel_obj.push(fuel);
// 				total_petrol_loss.push((fuel * price).toFixed(2));
// 				total_petrol_quantity.push(fuel);
// 			}
// 			else if (vehicles_data[i].fuel_type == 'gasoline' || vehicles_data[i].fuel_type == 'GASOLINE') {
// 				price = appconfig.gasoline_price;
// 				fuel_obj.push(fuel);
// 				total_gasoline_loss.push((idling_data[0].fuel * price).toFixed(2));
// 				total_gasoline_quantity.push(idling_data[0].fuel);
// 			}
// 			else {
// 				diesel_vehicle_count++;
// 				price = appconfig.diesel_price;
// 				fuel = idling_data[0].fuel;
// 				if (us_unit) {
// 					price = appconfig.gasoline_price;
// 					fuel = unitsSvc.toGallons(fuel);
// 				}
// 				fuel_obj.push(fuel);
// 				total_diesel_loss.push((fuel * price).toFixed(2));
// 				total_diesel_quantity.push(fuel);
// 			}
// 			if (kwd)
// 				total_fuel.push((fuel * price).toFixed(2));
// 			else
// 				total_fuel.push((fuel * price).toFixed(0));
// 			total_time.push((idling_data[0].timing / 60).toFixed(0));
// 			final_fuel_count = final_fuel_count + fuel;
// 			final_time_count = final_time_count + idling_data[0].timing / 60;

// 		} else {
// 			fuel_obj.push(0);
// 			total_fuel.push(0);
// 			total_time.push(0);

// 		}

// 	}
// 	final_fuel_count = (final_fuel_count).toFixed(2);
// 	final_time_count = addArrayOfElements(total_diesel_loss) + addArrayOfElements(total_cng_loss) + addArrayOfElements(total_petrol_loss) + addArrayOfElements(total_gasoline_loss);

// 	var obj = [];
// 	obj.push({
// 		idledata: [total_time, total_fuel],
// 		idlelabel: vehicles,
// 		final_fuel_count: final_fuel_count,
// 		final_time_count: final_time_count,
// 		total_diesel_loss: addArrayOfElements(total_diesel_loss),
// 		total_cng_loss: addArrayOfElements(total_cng_loss),
// 		total_petrol_loss: addArrayOfElements(total_petrol_loss),
// 		total_gasoline_loss: addArrayOfElements(total_gasoline_loss),
// 		total_diesel_quantity: addArrayOfElements(total_diesel_quantity),
// 		total_cng_quantity: addArrayOfElements(total_cng_quantity),
// 		total_petrol_quantity: addArrayOfElements(total_petrol_quantity),
// 		total_gasoline_quantity: addArrayOfElements(total_gasoline_quantity),
// 		diesel_vehicle_count: diesel_vehicle_count,
// 		cng_vehicle_count: cng_vehicle_count,
// 		petrol_vehicle_count: petrol_vehicle_count,
// 		fuel_obj: fuel_obj
// 	});
// 	return obj;

// };

// export let toUITypeGraph = function (type_data, vehicles_data) {
// 	var vehicles = [];
// 	var count = [];
// 	var vehicles1 = [];
// 	var count1 = [];
// 	var vehicles2 = [];
// 	var count2 = [];
// 	hard_brake_result = [];
// 	over_acceleration_result = [];
// 	over_speed_result = [], hard_brake_vehicles = [], over_acceleration_count = [], over_speed_count = [];
// 	if (type_data && type_data.data && type_data.data[0] && type_data.data[0].hard_brake)
// 		hard_brake_result = type_data.data[0].hard_brake;
// 	if (type_data && type_data.data && type_data.data[0] && type_data.data[0].over_acceleration)
// 		over_acceleration_result = type_data.data[0].over_acceleration;
// 	if (type_data && type_data.data && type_data.data[0] && type_data.data[0].over_speed)
// 		over_speed_result = type_data.data[0].over_speed;

// 	//     // hard break
// 	if (!vehicles_data || vehicles_data == undefined) {
// 		return ({
// 			hard_brake_vehicles: '',
// 			hard_brake_count: '',
// 			over_acceleration_count: '',
// 			over_acceleration_vehicles: '',
// 			over_speed_count: '',
// 			over_speed_vehicles: ''
// 		});
// 	}

// 	for (var i = 0; i < vehicles_data.length; i++) {
// 		var filteredId = _.filter(hard_brake_result, {
// 			vid: vehicles_data[i].id
// 		});

// 		if (filteredId.length > 0) {
// 			count.push(filteredId[0].count);
// 			vehicles.push(vehicles_data[i].tagged);
// 		} else {
// 			count.push(0);
// 			vehicles.push(vehicles_data[i].tagged);
// 		}
// 	}

// 	hard_brake_count = [count];
// 	hard_brake_vehicles = vehicles;

// 	//     // over acceleration

// 	for (var i = 0; i < vehicles_data.length; i++) {
// 		var filteredId = _.filter(over_acceleration_result, {
// 			vid: vehicles_data[i].id
// 		});

// 		if (filteredId.length > 0) {
// 			count1.push(filteredId[0].count);
// 			vehicles1.push(vehicles_data[i].tagged);
// 		} else {
// 			count1.push(0);
// 			vehicles1.push(vehicles_data[i].tagged);
// 		}
// 	}


// 	over_acceleration_count = [count1];
// 	over_acceleration_vehicles = vehicles1;

// 	//     // over speeding
// 	for (var i = 0; i < vehicles_data.length; i++) {
// 		var filteredId = _.filter(over_speed_result, {
// 			vid: vehicles_data[i].id
// 		});

// 		if (filteredId.length > 0) {
// 			count2.push(filteredId[0].count);
// 			vehicles2.push(vehicles_data[i].tagged);
// 		} else {
// 			count2.push(0);
// 			vehicles2.push(vehicles_data[i].tagged);
// 		}
// 	}
// 	over_speed_count = [count2];
// 	over_speed_vehicles = vehicles2;


// 	var obj = [];
// 	obj.push({
// 		hard_brake_vehicles: hard_brake_vehicles,
// 		hard_brake_count: hard_brake_count,
// 		over_acceleration_count: over_acceleration_count,
// 		over_acceleration_vehicles: over_acceleration_vehicles,
// 		over_speed_count: over_speed_count,
// 		over_speed_vehicles: vehicles2
// 	});
// 	return obj;
// };

// export let toUIIdlingPoints = function (points) {
// 	var returnObj = {};
// 	var days = [];
// 	var minutes = [];
// 	var fuel = [];
// 	var cost = [];
// 	var rate = 44;
// 	var totalConsumed = 0;
// 	var total = 0;
// 	for (var i = 0; i < points.length; i++) {
// 		var p = points[i];
// 		var date = new Date(p.time);
// 		if (date.getTime() > Date.now()) {
// 			continue;
// 		}
// 		var day = getDayString(p.time);
// 		var time = (p.tota_idle / 60).toFixed(0);
// 		var consumed;
// 		totalConsumed += p.fuel_consumed;
// 		if (p.fuel_consumed)
// 			consumed = p.fuel_consumed.toFixed(2);
// 		else
// 			consumed = 0;
// 		days.push(day);
// 		minutes.push(time);
// 		fuel.push(consumed);
// 		total += p.fuel_consumed * rate;
// 		cost.push((p.fuel_consumed * rate).toFixed(1));
// 	}
// 	return {
// 		days: days,
// 		minutes: minutes,
// 		fuel: fuel,
// 		cost: cost,
// 		total: total.toFixed(1),
// 		totalConsumed: totalConsumed.toFixed(2)
// 	};
// };

// export let toUIDailyReportNew = function (obj) {
// 	var newresult = [];

// 	for (var index = 0; index < obj.result.length; index++) {
// 		var element = obj.result[index];
// 		if (element.reports) {
// 			for (var j = 0; j < element.reports.length; j++) {
// 				var result = element.reports[j];
// 				var tobj = {
// 					fuel_sum: result.fuel_sum,
// 					time: result.time,
// 					dist_sum: result.dist_sum,
// 					total_moving_time: result.total_moving_time,
// 					idling_time: secondsToMinutes(result.idling_time_sec),
// 					idle_fuel_cons: result.fuel_consumed_idling,
// 					display_time: getDayString(result.time)
// 				};

// 				if (j === 0) {
// 					tobj.tagged = element.tag;
// 				}
// 				newresult.push(tobj);
// 			}
// 		}
// 	}
// 	return newresult;
// };

// var getDayString = function (timestamp) {
// 	var currentDate = new Date();
// 	var d = new Date(timestamp);
// 	var month = months[d.getMonth()];
// 	var str = d.getDate() + ' ' + month;
// 	//if(currentDate.getFullYear() !== d.getFullYear())
// 	str += ' ' + d.getFullYear();
// 	return str;
// };

// export let toUIFilledAlerts = function (filledAlerts) {
// 	if (filledAlerts.length) {
// 		for (var i = 0; i < filledAlerts.length; i++) {
// 			if (i == 0)
// 				var total_fuel_consumed = 0;
// 			else {
// 				var total_fuel_consumed = (filledAlerts[i - 1].obj.total - filledAlerts[i].obj.fuel_filled_level).toFixed(0);
// 				filledAlerts[i].obj.distanced_covered = filledAlerts[i].obj.distance - filledAlerts[i - 1].obj.distance;
// 				filledAlerts[i].obj.total_fuel_consumed = parseInt(total_fuel_consumed);
// 			}
// 		}
// 	}
// 	return filledAlerts;
// };

// export let toUIAdblueFilledAlerts = function (filledAlerts) {
// 	if (filledAlerts.length) {
// 		for (var i = 0; i < filledAlerts.length; i++) {
// 			if (i == 0)
// 				var total_adblue_consumed = 0;
// 			else {
// 				var total_adblue_consumed = (filledAlerts[i - 1].obj.total - filledAlerts[i].obj.adblue_filled_level).toFixed(0);
// 				filledAlerts[i].obj.distanced_covered = filledAlerts[i].obj.distance - filledAlerts[i - 1].obj.distance;
// 				filledAlerts[i].obj.total_adblue_consumed = parseInt(total_adblue_consumed);
// 			}
// 		}
// 	}
// 	return filledAlerts;
// };

// export let toUIDowntimeGraph = function (downtime, vehicles_data) {
// 	var vehicles = [];
// 	var downtime_count = [];
// 	var downtime_obj = [];
// 	if (downtime)
// 		downtime_obj = downtime;

// 	for (var i = 0; i < vehicles_data.length; i++) {
// 		var filteredId = _.filter(downtime_obj, {
// 			tagged: vehicles_data[i].tagged
// 		});

// 		if (filteredId.length > 0) {
// 			downtime_count.push(filteredId[0].downdays);
// 			vehicles.push(vehicles_data[i].tagged);
// 		} else {
// 			downtime_count.push(0);
// 			vehicles.push(vehicles_data[i].tagged);
// 		}
// 	}

// 	var obj = [];
// 	obj.push({
// 		downtime_count: [downtime_count],
// 		downtime_vehicles: vehicles
// 	});
// 	return obj;
// };

// export let toUIPerformanceGraphs = function (data, fuel_type, appconfig) {

// 	if (fuel_type == 'diesel')
// 		price = appconfig.diesel_price;

// 	if (fuel_type == 'CNG')
// 		price = appconfig.cng_price;

// 	if (fuel_type == 'petrol')
// 		price = appconfig.petrol_price;

// 	var idling_data = []; var idling_labels = [];
// 	var mileage_data = []; var mileage_labels = [];
// 	var speeding_data = []; var speeding_labels = [];
// 	var geareval_data = [[], [], []]; var geareval_labels = [];
// 	var hardbreak_data = []; var hardbreak_labels = [];
// 	var total_idling_minutes = []; var total_loss_rupees = []; var total_fuel = [];
// 	var mileage_fuel = []; var mileage_distance = [];

// 	if (data.mileage && data.mileage.length > 0) {
// 		for (var i = 0; i < data.mileage.length; i++) {
// 			var e = data.mileage[i];
// 			if (e.mileage == null || e.mileage == undefined)
// 				mileage_data.push(0);
// 			else
// 				mileage_data.push(parseFloat((e.mileage.toFixed(2))));

// 			if (e.fuel == null || e.fuel == undefined)
// 				mileage_fuel.push(0);
// 			else
// 				mileage_fuel.push(parseFloat((e.fuel.toFixed(2))));

// 			if (e.distance == null || e.distance == undefined)
// 				mileage_distance.push(0);
// 			else
// 				mileage_distance.push(parseFloat((e.distance.toFixed(2))));

// 			mileage_labels.push(getDayString(e.day));
// 		}
// 	} else {

// 	}


// 	if (data.gear_standstill && data.gear_standstill.length > 0) {
// 		for (var i = 0; i < data.gear_standstill.length; i++) {
// 			var first = 0;
// 			var second = 0;
// 			var toa = 0;

// 			var e = data.gear_standstill[i];
// 			if (e.first == null || e.first == undefined)
// 				first = 0;
// 			else
// 				first = parseFloat(e.first);

// 			if (e.second == null || e.second == undefined)
// 				second = 0;
// 			else
// 				second = parseFloat(e.second);

// 			if (e.third == null || e.third == undefined)
// 				toa = 0;
// 			else
// 				toa = parseFloat(e.third);

// 			var total = first + second + toa;
// 			geareval_data[0].push(gearEval(first, total));
// 			geareval_data[1].push(gearEval(second, total));
// 			geareval_data[2].push(gearEval(toa, total));
// 			geareval_labels.push(getDayString(e.day));
// 		}
// 	} else {
// 		geareval_labels = mileage_labels;
// 		for (var index = 0; index < mileage_data.length; index++) {
// 			geareval_data[0].push(0);
// 			geareval_data[1].push(0);
// 			geareval_data[2].push(0);
// 		}
// 	}

// 	if (data.hard_brake) {
// 		for (var j = 0; j < data.hard_brake.length; j++) {
// 			var d = data.hard_brake[j];
// 			if (d.count == null || d.count == undefined)
// 				hardbreak_data.push(0);
// 			else
// 				hardbreak_data.push(d.count);

// 			hardbreak_labels.push(getDayString(d.day));
// 		}
// 	} else {
// 		hardbreak_labels = mileage_labels;
// 		for (var index = 0; index < mileage_data.length; index++) {
// 			hardbreak_data.push(0);
// 		}
// 	}

// 	if (data.idling && data.idling.length > 0) {
// 		for (var o = 0; o < data.idling.length; o++) {

// 			var p = data.idling[o];

// 			if (p.timing != null || p.timing != undefined)
// 				total_idling_minutes.push(parseFloat((p.timing / 60).toFixed(0)));
// 			else
// 				total_idling_minutes.push(0);

// 			if (p.fuel != null || p.fuel != undefined)
// 				total_loss_rupees.push(parseFloat((p.fuel * price).toFixed(0)));
// 			else
// 				total_loss_rupees.push(0);

// 			if (p.fuel != null || p.fuel != undefined)
// 				total_fuel.push(p.fuel);
// 			else
// 				total_fuel.push(0);

// 			idling_labels.push(getDayString(p.day));

// 		}
// 	} else {

// 		idling_labels = mileage_labels;
// 		for (var index = 0; index < mileage_data.length; index++) {
// 			total_loss_rupees.push(0);
// 			total_idling_minutes.push(0);
// 			total_fuel.push(0);

// 		}
// 	}

// 	if (data.over_speed) {
// 		for (var j = 0; j < data.over_speed.length; j++) {
// 			var d = data.over_speed[j];
// 			if (d.count == null || d.count == undefined)
// 				speeding_data.push(0);
// 			else
// 				speeding_data.push(d.count);

// 			speeding_labels.push(getDayString(d.day));
// 		}
// 	} else {
// 		speeding_labels = mileage_labels;
// 		for (var index = 0; index < mileage_data.length; index++) {
// 			speeding_data.push(0);
// 		}
// 	}

// 	return {
// 		idling_data: [total_idling_minutes, total_loss_rupees],
// 		idling_labels: idling_labels,
// 		mileage_data: mileage_data,
// 		mileage_labels: mileage_labels,
// 		speeding_data: [speeding_data],
// 		speeding_labels: speeding_labels,
// 		geareval_data: geareval_data,
// 		geareval_labels: geareval_labels,
// 		hardbreak_data: [hardbreak_data],
// 		hardbreak_labels: hardbreak_labels,
// 		idling_total_time: minutesToHours(addArrayOfElements(total_idling_minutes)),
// 		idling_total_fuel: addArrayOfElements(total_fuel),
// 		idling_total_price: addArrayOfElements(total_loss_rupees),
// 		mileage_info: [addArrayOfElements(mileage_distance), addArrayOfElements(mileage_fuel)],
// 		km_drivens: mileage_distance
// 	};
// };

// var gearEval = function (a, t) {
// 	var e = (a / t * 100);
// 	return e || 0;
// };

// var addArrayOfElements = function (o) {
// 	var count = 0;
// 	for (var index = 0; index < o.length; index++) {
// 		count += parseFloat(o[index]);
// 	}
// 	return count;
// };

// var minutesToHours = function (MINUTES) {
// 	m = MINUTES % 60;
// 	h = (MINUTES - m) / 60;
// 	return [h, m];
// };
