import { put, call, takeLatest, all, fork, select } from 'redux-saga/effects';
import { setVehicleList, updateAddresses, setVehicleListFromApi, getVehicleByIdFromApiFailed, getVehicleByIdFromApiSuccess, getVehicleCount, getVehicleByPlateSuccess } from '../actions/app.actions';
import { toUIVehicleList } from '../helpers/model';
// import getVehicleFromRealm from '../../db/realms/getVehicles.realm';
import { GET_VEHICLE_LIST_FAILED, GET_VEHICLE_LIST_START, GET_VEHICLE_LIST_FROM_API_START, FETCH_ADDRESS_START, GET_VEHICLE_COUNTS_START, GET_VEHICLE_COUNTS_FAILED, GET_VEHICLE_COUNTS_SUCCESS, GET_VEHICLE_BY_ID_FROM_API_START, GET_VEHICLE_BY_PLATE_FROM_API } from '../constants';
// import fetchAddresses from '../apis/fetchAddresses';
import get from 'lodash/get';
import Intangles from '../sdk';
// import { getVehicleFilters } from '../helpers/storeHelpers';
// import getVehicleCountsFromRealm from '../../db/realms/getVehicleCounts.realm';
// function* getVehicleList(action) {
// 	try {
// 		var currentFilters = getVehicleFilters();
// 		var options = {
// 			...(action.options || {})
// 		}
// 		if (!action.options || !action.options.noFilter) {
// 			options = {
// 				...options,
// 				...currentFilters
// 			}
// 		}
// 		let rawData = yield call(getVehicleFromRealm, options);
// 		let data = toUIVehicleList(rawData);
// 		yield put(setVehicleList(data, action.options.concat));
// 		yield put({ type: FETCH_ADDRESS_START, rawData });
// 	}
// 	catch (error) {
// 		yield put({
// 			type: GET_VEHICLE_LIST_FAILED,
// 			error: error.message
// 		});
// 	}
// }

// function* fetchAddressesSaga(action) {
// 	try {
// 		var rawData = action.rawData;
// 		if (rawData && rawData.v && rawData.v.length) {
// 			var skippedIndexes = [];
// 			var addresses = yield select((state) => get(state, 'app.addresses'));
// 			var latLngs = rawData.v.map(function (vehicle, index) {
// 				if (!vehicle.location || addresses[vehicle.id]) {
// 					skippedIndexes.push(index);
// 					return {
// 						lat: 18,
// 						lng: 73
// 					};
// 				}
// 				return {
// 					lat: vehicle.location[1],
// 					lng: vehicle.location[0]
// 				}
// 			});
// 			var response = yield call(fetchAddresses, { geo_locs: latLngs, third_party: true });
// 			if (response.status && response.status.code == 200) {
// 				var addressesResponse = response.result.address;
// 				if (addressesResponse && addressesResponse.length) {
// 					var addressIndex = 0;
// 					var updatedAddress = rawData.v.reduce(function (prev, vehicle, index) {
// 						if (skippedIndexes.indexOf(index) === -1) {
// 							prev[vehicle.id] = addressesResponse[addressIndex];
// 							addressIndex++;
// 						}
// 						return prev;
// 					}, {})
// 					yield put(updateAddresses(updatedAddress))
// 				}
// 			}
// 		}
// 	} catch (error) {
// 		console.tron.log(error.message);
// 	}
// }

// function* getVehicleListSaga() {
// 	yield takeLatest(GET_VEHICLE_LIST_START, getVehicleList);
// }

// function* getVehicleListFromApi(action) {
// 	try {
// 		let rawData = yield call(Intangles.Vehicle.getVehicleList, action.options);
// 		let data = toUIVehicleList(rawData);
// 		yield put(setVehicleListFromApi(data, action.options.concat));
// 		if (rawData && rawData.v && rawData.v.length && action.getAddress) {
// 			var skippedIndexes = [];
// 			var addresses = yield select((state) => get(state, 'app.addresses'));
// 			var latLngs = rawData.v.map(function (vehicle, index) {
// 				if (!vehicle.location || addresses[vehicle.id]) {
// 					skippedIndexes.push(index);
// 					return {
// 						lat: 18,
// 						lng: 73
// 					};
// 				}
// 				return {
// 					lat: vehicle.location[1],
// 					lng: vehicle.location[0]
// 				}
// 			});
// 			var response = yield call(fetchAddresses, { geo_locs: latLngs, third_party: true });
// 			if (response.status && response.status.code == 200) {
// 				var addressesResponse = response.result.address;
// 				if (addressesResponse && addressesResponse.length) {
// 					var addressIndex = 0;
// 					var updatedAddress = rawData.v.reduce(function (prev, vehicle, index) {
// 						if (skippedIndexes.indexOf(index) === -1) {
// 							prev[vehicle.id] = addressesResponse[addressIndex];
// 							addressIndex++;
// 						}
// 						return prev;
// 					}, {})
// 					yield put(updateAddresses(updatedAddress))
// 				}
// 			}
// 		}
// 	}
// 	catch (error) {
// 		yield put({
// 			type: GET_VEHICLE_LIST_FAILED,
// 			error: error.message
// 		});
// 	}
// }

// function * getVehicleCounts(action) {
// 	try{
// 		var currentFilters = getVehicleFilters();
// 		let counts = yield call(getVehicleCountsFromRealm, currentFilters);
// 		yield put({
// 			type: GET_VEHICLE_COUNTS_SUCCESS,
// 			counts
// 		})
// 	} catch(error) {
// 		yield put({
// 			type: GET_VEHICLE_COUNTS_FAILED,
// 			error: error.message
// 		})
// 	}
// }

// Customer token goes here
const token =  'TOMK-GP8W_nyKzOHJoR00zzwLS5GEJP2XCOo276c1eFt3Crwlmxud9-1xuRoRgYG';
//TOMK-GP8W_nyKzOHJoR00zzwLS5GEJP2XCOo276c1eFt3Crwlmxud9-1xuRoRgYG

async function getVehicleInfoByPlate(params){
	try {
		const {vehiclePlate, vendorAccessToken, options} = params;
		let url = `https://indium-apis.intangles.io/api/v1/vendor/vehicle/list/?psize=2&pnum=1&unmasked_ids=true&plate=${vehiclePlate}`
		const res = await fetch(url, {headers: {'vendor-access-token': vendorAccessToken}});

		const data = await res.json();

		if(data?.status?.code === 200){
			return data;
		}
		else{
			throw Error("failed to fetch")
		}

	} catch (error) {
		console.log(error)
	}
}

function* getVehicleByIdFromApi(action) {
    try {
        var vehicleId = action.vehicleId;
		var options = action.options || {};
        var rawData = yield call(Intangles.Vehicle.getVehicleList, { query: { vid: vehicleId },  ...options});
        let data = toUIVehicleList(rawData);
        yield put(setVehicleList(data));
    } catch(error) {
        yield put(getVehicleByIdFromApiFailed(error.message || error));
    }
    
}

function* getVehicleByPlateFromVendorApi(action){
	try{
		const vehiclePlate = action.vehiclePlate;
		const vendorAccessToken = action.vendorAccessToken;
		const options = action.options || {};
		const result = yield call(getVehicleInfoByPlate, {vehiclePlate, vendorAccessToken, options});
		const vehicles = result?.result?.vehicles[0];
        yield put(getVehicleByPlateSuccess(vehiclePlate, vehicles));
	}catch(err){
		yield put(getVehicleByIdFromApiFailed(err.message || err));
	}
}

// function* getVehicleListFromApiSaga() {
// 	yield takeLatest(GET_VEHICLE_LIST_FROM_API_START, getVehicleListFromApi);
// }

// function * startAddressFetchSaga() {
// 	yield takeLatest(FETCH_ADDRESS_START, fetchAddressesSaga);
// }

// function * getVehicleCountsSaga() {
// 	yield takeLatest(GET_VEHICLE_COUNTS_START, getVehicleCounts);
// }

function * getVehicleByIdFromApiSaga() {
    yield takeLatest(GET_VEHICLE_BY_ID_FROM_API_START, getVehicleByIdFromApi);
	
}

function* getVehicleByPlatApiSaga(){
	yield takeLatest(GET_VEHICLE_BY_PLATE_FROM_API, getVehicleByPlateFromVendorApi);
}

export default function* () {
	yield all([
        fork(getVehicleByIdFromApiSaga),
		fork(getVehicleByPlatApiSaga)
		// fork(getVehicleListSaga),
		// fork(getVehicleListFromApiSaga),
		// fork(startAddressFetchSaga),
		// fork(getVehicleCountsSaga)
		//fork(accountsSaga),
		// fork(vehicleListSaga),
		// fork(specsListSaga),
		// fork(getAccountDataSaga),
		// fork(fetchAlertsSaga),
		// fork(fetchAlertCountsSaga),
	]);
}