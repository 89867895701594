import {
	SET_USER, GET_SPECS_LIST_FAILED, VEHICLE_MSG_RECEIVED,
	GET_VEHICLE_LIST_FAILED, GET_SPECS_LIST, SET_SPECS_LIST,
	GET_VEHICLE_LIST_START, GET_VEHICLE_LIST_SUCCESS, CHANGE_DRAWER_STATE, LOGOUT_USER, GET_ACCOUNT_SPECS_DATA_SUCCESS, GET_ACCOUNT_SPECS_DATA, GET_ACCOUNT_SPECS_DATA_FAILED, FETCH_ALL_ACCOUNTS_TO_REALM, FETCH_ALL_ACCOUNTS_TO_REALM_SUCCESS, FETCH_ALL_ACCOUNTS_TO_REALM_FAILED, UPDATE_VEHICLES_IN_REALM, UPDATE_VEHICLES_IN_REALM_SUCCESS, UPDATE_VEHICLES_IN_REALM_FAILED, FETCH_ACCOUNT_INFO_TO_REALM, FETCH_ACCOUNT_INFO_TO_REALM_SUCCESS, FETCH_ACCOUNT_INFO_TO_REALM_FAILED, UPDATE_CURRENT_ACCOUNT_VEHICLES, UPDATE_CURRENT_ACCOUNT_VEHICLES_SUCCESS, GET_DTC_INFO_SUCCESS, UPDATE_ADDRESSES, FETCH_NOTIFICATIONS_START, TOGGLE_DETAILS_MODAL, SET_DETAILS_MODAL_DATA, GET_VEHICLE_LIST_FROM_API_START, GET_VEHICLE_LIST_FROM_API_SUCCESS, GET_VEHICLE_LIST_FROM_API_FAILED, SET_NEW_NOTIFICATIONS_INDICATOR, SET_NETWORK_INFO, SET_AVAILABLE_ALERT_TYPES, SET_REDIRECT_INFO, SET_APP_STATE, SET_VEHICLE_FILTERS, GET_VEHICLE_COUNTS_SUCCESS, VEHICLE_STATE_CHANGED, GET_VEHICLE_BY_PLATE_FROM_API, GET_VEHICLE_BY_PLATE_FROM_API_SUCCESS, VEHICLE_MSG_RECEIVED_FOR_PLATE
} from '../constants';
import { initialState } from '../constants';
import { SET_ACCOUNT_DATA } from '../constants';
import { get, set } from 'lodash';
export default function (state = initialState, action) {
	switch (action.type) {
		case SET_USER: return {
			...state,
			user: action.data.user
		};
		case GET_VEHICLE_LIST_START: return {
            ...state,
            vehicles: {
				...state.vehicles,
				list: action.concat ? state.vehicles.list || [] : [],
                loading: true,
                loaded: false,
                error: false
            }
        }

		case GET_VEHICLE_BY_PLATE_FROM_API :
			return {
				...state,
				vehicles: {
					...state.vehicles,
					loading: true,
					loaded: false,
					error: false,
					vehicleFromPlate: null,
				}
			}
		case GET_VEHICLE_BY_PLATE_FROM_API_SUCCESS :{
			return {
				...state,
				vehicles:{
                    ...state.vehicles,
                    hashedVehicles: {
                        ...state.vehicles.hashedVehicles,
                        [action.vehiclePlate] : {...action.vehicles},
                    }
                },
			}
		}

        case GET_VEHICLE_LIST_SUCCESS:
            if (!action.vehicles)
                action.vehicles = {};
            if (!action.vehicles.list)
                action.vehicles.list = [];
            var vehiclesObj = {
                ...state.vehicles
            }
            if(action.concat && vehiclesObj.list) {
                vehiclesObj.list = vehiclesObj.list.concat(action.vehicles.list);
            }
            else {
                vehiclesObj.list = [ ...action.vehicles.list ];
            }
            vehiclesObj.paging = action.vehicles.paging;
            vehiclesObj.status = action.vehicles.status;
            vehiclesObj.hashedVehicles = { ...vehiclesObj.hashedVehicles, ...action.vehicles.entities.vehicles};
            delete vehiclesObj.entities;
            return {
                ...state,
                vehicles: {
                    ...vehiclesObj,
                    loading: false,
                    loaded: true,
                    error: false
                }
            }
        case GET_VEHICLE_LIST_FAILED: return {
            ...state,
            vehicles: {
                list: [],
                loading: false,
                loaded: false,
                error: false
            }
		}
		case GET_VEHICLE_LIST_FROM_API_START: return {
            ...state,
            vehiclesFromApi: {
				...state.vehicles,
				list: action.concat ? state.vehicles.list || [] : [],
                loading: true,
                loaded: false,
                error: false
            }
        }
        case GET_VEHICLE_LIST_FROM_API_SUCCESS:
            if (!action.vehicles)
                action.vehicles = {};
            if (!action.vehicles.list)
                action.vehicles.list = [];
            var vehiclesObj = {
                ...state.vehicles
            }
            if(action.concat && vehiclesObj.list) {
                vehiclesObj.list = vehiclesObj.list.concat(action.vehicles.list);
            }
            else {
                vehiclesObj.list = [ ...action.vehicles.list ];
            }
            vehiclesObj.paging = action.vehicles.paging;
            vehiclesObj.status = action.vehicles.status;
            vehiclesObj.hashedVehicles = { ...vehiclesObj.hashedVehicles, ...action.vehicles.entities.vehicles};
            delete vehiclesObj.entities;
            return {
                ...state,
                vehiclesFromApi: {
                    ...vehiclesObj,
                    loading: false,
                    loaded: true,
                    error: false
                }
            }
        case GET_VEHICLE_LIST_FROM_API_FAILED: return {
            ...state,
            vehiclesFromApi: {
                list: [],
                loading: false,
                loaded: false,
                error: false
            }
		}
		case VEHICLE_STATE_CHANGED: {
			var message = get(action, 'msg.message');
			if(!message)
				return state;
			var vehicleId = message.vehicle_id;
			if(!vehicleId)
				return state;
			var newStatus = get(message,"state_obj.state");
			var timestamp = get(message,"state_obj.timestamp");
			set(state, "vehicles.hashedVehicles."+ vehicleId + ".status", newStatus);
			set(state, "vehicles.hashedVehicles."+ vehicleId + ".state_object", {
				state: newStatus,
				last_update: timestamp
			})
			return {
				...state
			}
		}
		case VEHICLE_MSG_RECEIVED:
			var message = get(action,'msg.message.tracker');
            if (!message)
                return {
                    ...state
                }
            //var index = state.selectedDeviceIds.indexOf(message.id);
			var vehicleId = message.vid;
            if (!vehicleId)
                return {
                    ...state
                }
            var vehicle = Object.assign({},get(state, "vehicles.hashedVehicles."+ vehicleId ));
            if(!vehicle.last_state)
				vehicle.last_state = {};
			if (vehicle.last_state && vehicle.last_state.loc && !vehicle.polyline)
				vehicle.polyline = [
					{
						latitude: parseFloat(vehicle.last_state.loc.lat),
						longitude: parseFloat(vehicle.last_state.loc.lng),
						lat: parseFloat(vehicle.last_state.loc.lat),
						lng: parseFloat(vehicle.last_state.loc.lng)
					}
				];
            vehicle.last_state.speed = message.sp;
            vehicle.last_state.sp = message.sp;
            vehicle.timestamp = (new Date()).getTime();
            vehicle.last_state.timestamp = (new Date()).getTime();
            vehicle.last_state.hd = message.hd;
            if (message.geocode && message.geocode.lat && message.geocode.lng) {
                vehicle.lastLoc = message.geocode;
                if (vehicle.last_state) {
                    vehicle.last_state.loc = vehicle.lastLoc;
                }
                if (vehicle.lastLoc) {
                    var lat = parseFloat(vehicle.lastLoc.lat);
                    var lng = parseFloat(vehicle.lastLoc.lng);
                    if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
						if(vehicle.polyline) {
							vehicle.polyline = vehicle.polyline.concat({
								latitude: lat,
								longitude: lng,
								lat,
								lng
							});
						} else {
							vehicle.polyline = {
								latitude: lat,
								longitude: lng,
								lat,
								lng
							}
						}
					}
                        
                }
                vehicle.message = true;
            }
            return {
                ...state,
                vehicles:{
                    ...state.vehicles,
                    hashedVehicles: {
                        ...state.vehicles.hashedVehicles,
                        [vehicle.id] : vehicle
                    }
                },
            };
			case VEHICLE_MSG_RECEIVED_FOR_PLATE:
				var message = get(action,'msg.message.tracker');
				if (!message)
					return {
						...state
					}
				//var index = state.selectedDeviceIds.indexOf(message.id);
				var vehicleId = message.vid;
				if (!vehicleId)
					return {
						...state
					}
				var vehicle = Object.assign({},get(state, "vehicles.hashedVehicles."+ action.vehiclePlate ));
				if(!vehicle.last_state)
					vehicle.last_state = {};
				if (vehicle.last_state && vehicle.last_state.loc && !vehicle.polyline) {
					vehicle.polyline = [
						{
							latitude: parseFloat(vehicle.last_state.loc.lat),
							longitude: parseFloat(vehicle.last_state.loc.lng),
							lat: parseFloat(vehicle.last_state.loc.lat),
							lng: parseFloat(vehicle.last_state.loc.lng)
						}
					];
				}
				else if (vehicle?.gps_info && vehicle.gps_info.loc && !vehicle.polyline) {
					vehicle.polyline = [
						{
							latitude: parseFloat(vehicle.gps_info.loc.lat),
							longitude: parseFloat(vehicle.gps_info.loc.lng),
							lat: parseFloat(vehicle.gps_info.loc.lat),
							lng: parseFloat(vehicle.gps_info.loc.lng)
						}
					];
				}
				vehicle.last_state.speed = message.sp;
				vehicle.last_state.sp = message.sp;
				vehicle.timestamp = (new Date()).getTime();
				vehicle.last_state.timestamp = (new Date()).getTime();
				vehicle.last_state.hd = message.hd;
				if (message.geocode && message.geocode.lat && message.geocode.lng) {
					vehicle.lastLoc = message.geocode;
					if (vehicle.last_state) {
						vehicle.last_state.loc = vehicle.lastLoc;
					}
					if (vehicle.lastLoc) {
						var lat = parseFloat(vehicle.lastLoc.lat);
						var lng = parseFloat(vehicle.lastLoc.lng);
						if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
							if(vehicle.polyline && Array.isArray(vehicle.polyline)) {
								vehicle.polyline = vehicle.polyline.concat({
									latitude: lat,
									longitude: lng,
									lat,
									lng
								});
							} else {
								vehicle.polyline = [{
									latitude: lat,
									longitude: lng,
									lat,
									lng
								}]
							}
						}
							
					}
					vehicle.message = true;
				}
				return {
					...state,
					vehicles:{
						...state.vehicles,
						hashedVehicles: {
							...state.vehicles.hashedVehicles,
							[action.vehiclePlate] : vehicle
						}
					},
				};
		case GET_SPECS_LIST: return {
			...state,
			specs: {
				loading: true,
				error: false,
				loaded: false
			}
		};
		case GET_SPECS_LIST_FAILED: return {
			...state,
			specs: {
				hashedSpecs: {},
				loading: false,
				error: action.error,
				loaded: false
			}
		};
		case SET_SPECS_LIST: return {
			...state,
			specs: {
				hashedSpecs: get(action, "specs.entities.specs"),
				list: get(action, "specs.result.specs"),
				paging: action.specs.paging,
				loading: false,
				error: false,
				loaded: true
			}
		};
		case SET_ACCOUNT_DATA: return {
			...state,
			accountConfig: {
				loading: true,
				loaded: false,
				error: false,
				uiConfig: action.account.config,
				alertConfig: action.account.alertconfig,
				id: action.account.id,
				name: action.account.name,
				displayName: action.account.displayName
			}
		};

		case CHANGE_DRAWER_STATE: return {
			...state,
			drawerState: action.state
		};
		
		case GET_ACCOUNT_SPECS_DATA : return {
			...state,
			gotData: false,
			error: false
		}

		case GET_ACCOUNT_SPECS_DATA_FAILED: return {
			...state,
			gotData: false,
			error: action.error
		}

		case GET_ACCOUNT_SPECS_DATA_SUCCESS: return {
			...state,
			gotData: true,
			error: false
		}

		case UPDATE_VEHICLES_IN_REALM: return {
			...state,
			updatingVehicles: true,
			vehiclesUpdated: false,
			failedToUpdateVehicles: false
		}

		case UPDATE_VEHICLES_IN_REALM_SUCCESS: return {
			...state,
			updatingVehicles: false,
			vehiclesUpdated: true,
			failedToUpdateVehicles: false
		}

		case UPDATE_VEHICLES_IN_REALM_FAILED: return {
			...state,
			updatingVehicles: false,
			vehiclesUpdated: false,
			failedToUpdateVehicles: action.error
		}

		case FETCH_ALL_ACCOUNTS_TO_REALM: return {
			...state,
			fetchingAllAccounts: true,
			fetchedAllAccounts: false,
			failedToFetchAllAccounts: false
		}

		case FETCH_ALL_ACCOUNTS_TO_REALM_SUCCESS: return {
			...state,
			fetchingAllAccounts: false,
			fetchedAllAccounts: true,
			failedToFetchAllAccounts: false
		}

		case FETCH_ALL_ACCOUNTS_TO_REALM_FAILED: return {
			...state,
			fetchingAllAccounts: false,
			fetchedAllAccounts: false,
			failedToFetchAllAccounts: action.error
		}

		case FETCH_ACCOUNT_INFO_TO_REALM: return {
			...state,
			fetchingAccountInfo: true,
			fetchedAccountIngo: false,
			failedToFetchAccountInfo: false
		}

		case FETCH_ACCOUNT_INFO_TO_REALM_SUCCESS: return {
			...state,
			fetchingAccountInfo: false,
			fetchedAccountIngo: true,
			failedToFetchAccountInfo: false
		}

		case FETCH_ACCOUNT_INFO_TO_REALM_FAILED: return {
			...state,
			fetchingAccountInfo: false,
			fetchedAccountIngo: false,
			failedToFetchAccountInfo: action.error
		}

		case UPDATE_CURRENT_ACCOUNT_VEHICLES_SUCCESS: 
			if(!action.data)
				return state;
			var list = state.vehicles.list;
			if(!list)
				return state;
			var oldList = get(state, 'vehicles.hashedVehicles',{});
			var vehiclesObj = {};
			vehiclesObj.hashedVehicles = { ...vehiclesObj.hashedVehicles, ...action.data.entities.vehicles};
			return {
                ...state,
                vehicles: {
					...state.vehicles,
					hashedVehicles: {
						...oldList,
						...vehiclesObj.hashedVehicles
					}
                }
			}
		case GET_DTC_INFO_SUCCESS:
			if(!action.dtcInfo)
				return state;
			return {
				...state,
				dtcs: {
					...state.dtcs,
					...action.dtcInfo
				}
			}
		case UPDATE_ADDRESSES:
			if(!action.addresses)
				return state;
			return {
				...state,
				addresses: {
					...state.addresses,
					...action.addresses
				}
			}
		case FETCH_NOTIFICATIONS_START:
			return {
				...state,
				notifications: {
					...state.notifications,
					fetching: true
				}
			}
		case SET_DETAILS_MODAL_DATA: return {
			...state,
			detailsModal: {
				...state.detailsModal,
				data: action.data
			}
		}
		case TOGGLE_DETAILS_MODAL: return {
			...state,
			detailsModal: {
				...state.detailsModal,
				isVisible: action.isVisible
			}
		}
		case SET_NEW_NOTIFICATIONS_INDICATOR: return {
			...state,
			newNotificationsIndicator: action.data
		}
		case SET_NETWORK_INFO: return {
			...state,
			netInfo: action.data
		}
		case SET_AVAILABLE_ALERT_TYPES: return {
			...state,
			availableAlertTypes: action.data
		}
		case SET_REDIRECT_INFO: return {
			...state,
			redirectInfo: action.data
		}
		case SET_APP_STATE: return {
			...state,
			active: action.active
		}
		case SET_VEHICLE_FILTERS: return {
			...state,
			vehicles: {
				...state.vehicles,
				filters: {
					...state.vehicles.filters,
					...action.filters
				}
			}
		}
		case GET_VEHICLE_COUNTS_SUCCESS: return {
			...state,
			vehicles: {
				...state.vehicles,
				counts: {
					...state.vehicles.counts,
					...action.counts
				}
			}
		}
		default: return state;
	}
}