import React, { useEffect, useState, useRef } from 'react';
import { Marker, Polyline, InfoWindow } from 'react-google-maps';
import { getVehicleByIdFromApiAction, vehicleMsgReceived, vehicleStateChangeReceived, getVehicleByPlateFromApiAction , vehicleMsgReceivedForPlate} from '../../../shared/actions/app.actions';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import { connectToDevice, onDevicePing } from '../../../shared/mqtt';
import { MQTT_EVENTS } from '../../../shared/constants';
import Vehicle from '../../../shared/classes/Vehicle';
import VehicleInfoWindow from './components/VehicleInfoWindow';
import { useHistory , useLocation} from "react-router-dom";



function VehicleMarkerFromParam(props) {
    const {vehicleId = null , vehiclePlate = null, vendorAccessToken = null} = props;
    const [redirectPath, setRedirectpath] = useState()

    const history = useHistory();
    var marker = useRef(null);
    var [ firstCallDone, setFirstCall ] = useState(false);
    var [ isInfoOpen, setInfoState ] = useState(true);

    const {pathname, search} = useLocation();

    var { vehicle, fetchingVehicles } = props;
    var latLng = null;
    var onMessageReceived = function (topic, message) {
        if (!message)
            return;
        if(topic.indexOf(MQTT_EVENTS.VEHICLE_LOC_DATA) > -1) {
            props.vehicleMsgReceived(message);
        } else if (topic.indexOf(MQTT_EVENTS.STATE_CHANGE) > -1) {
            props.vehicleStateChangeMsg(message);
        }
    }
    const onMessageReceivedWithPlate = function (topic, message) {
        if (!message)
            return;
        if(topic.indexOf(MQTT_EVENTS.VEHICLE_LOC_DATA) > -1) {
            props.vehicleMsgReceivedForPlate(message, vehiclePlate);
        } else if (topic.indexOf(MQTT_EVENTS.STATE_CHANGE) > -1) {
            props.vehicleMsgReceivedForPlate(message, vehiclePlate);
        }
    }
    if(vehicle && vehicle.lastLoc) {
        latLng = new window.google.maps.LatLng(vehicle.lastLoc.lat, vehicle.lastLoc.lng);
        props.onVehicleReady(latLng);
    }
    useEffect(() => {
        if(firstCallDone)
            return;
        setFirstCall(true);
        if(vehicleId){
            props.getVehicleInfoById(vehicleId, { lastLoc: true });
            connectToDevice(vehicleId, true);
            onDevicePing(onMessageReceived);
        }
    });

    useEffect(()=>{
        if( vehiclePlate && vendorAccessToken){
            props.getvehicleInfoByPlate(vehiclePlate, vendorAccessToken, { lastLoc: true });
        }
        if(vehicle?.id){
            connectToDevice(vehicle.id, true);
            onDevicePing(onMessageReceivedWithPlate);
            history.push(`${redirectPath}`);
        }else if(!fetchingVehicles){
            history.push('/404')
        }
    },[vehiclePlate, vehicle?.id])

    useEffect(()=>{
        if(!pathname.includes('404')){
            setRedirectpath(pathname + search)
        }
    },[pathname])


    var openInfoWindow = function() {
        setInfoState(true);
    }

    var onInfoClosed = function() {
        setInfoState(false);
    }
    return (
        <React.Fragment>
            {
                vehicle.id && vehicle.lastLoc &&
                <>
                <Marker ref={marker} options={{
                        icon: {
                            url: vehicle.setHeading(vehicle.hd),
                            // scaledSize: { width: 32, height: 32 },
                            anchor: new window.google.maps.Point(9, 9),
                            rotation: get(vehicle,'last_state.hd'),
                        },
                    }} position={latLng}
                    onClick={openInfoWindow}
                >
                    {
                        isInfoOpen && <VehicleInfoWindow onCloseClick={onInfoClosed} vehicle={vehicle}/>
                    }
                </Marker>
                <Polyline strokeColor={'green'} path={vehicle.polyline}></Polyline>
                </>
            }
        </React.Fragment>
    )
}

var vehicleSelector = createSelector(
    (state,props) => get(state, 'app.vehicles.hashedVehicles.' + props.vehicleId, {}),
    vehicle => new  Vehicle(vehicle)
    );
    
const vehicleSelectorForPlate = createSelector(
        (state, props) => get(state, 'app.vehicles.hashedVehicles.' + props?.vehiclePlate, {}),
        vehicle => new Vehicle(vehicle)
);

var mapStateToProps = (state, props) => {
    console.log(props);
    if(props?.vehicleId){
        return {
            vehicle: vehicleSelector(state, props)
        }
    }
    else if(props?.vehiclePlate){
        return {
            vehicle: vehicleSelectorForPlate(state, props),
            fetchingVehicles:  get(state, 'app.vehicles.loading', true),
        }
    }
}
var mapDispatchToProps = (dispatch) => ({
    getVehicleInfoById: function(vehicleId, options) {
        dispatch(getVehicleByIdFromApiAction(vehicleId, options));
    },
    getvehicleInfoByPlate: function(vehiclePlate, vendorAccessToken, options){
        dispatch(getVehicleByPlateFromApiAction(vehiclePlate, vendorAccessToken, options));
    },
    vehicleMsgReceived: function(message) {
        dispatch(vehicleMsgReceived(message));
    },
    vehicleStateChangeMsg: function(message) {
        dispatch(vehicleStateChangeReceived(message));
    },
    vehicleMsgReceivedForPlate:  function(message, vehiclePlate){
        dispatch(vehicleMsgReceivedForPlate(message, vehiclePlate))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(VehicleMarkerFromParam));