import React, { useRef, useEffect } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps"
function MapView(props) {
    const map = useRef(null);
    useEffect(() => props.setRef(map));
    return (
        <GoogleMap
            ref={map}
            defaultZoom={15}
            defaultCenter={{ lat: -34.397, lng: 150.644 }}
        >
            {props.children}
        </GoogleMap>
    )
}

export default withScriptjs(withGoogleMap(React.memo(MapView)));