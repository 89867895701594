import React, { useRef, useEffect, useState, useReducer } from 'react';
import MapView from '../../components/MapView';
import StretchedContainer from '../../styled/StretchedContainer';
import { MAP_URL } from '../../../shared/constants';
import VehicleMarkerFromParam from '../../components/VehicleMarkerFromParam/VehicleMarkerFromParam';
import Intangles from '../../../shared/sdk';
import {useLocation , useParams} from "react-router-dom";
import { initSocket } from '../../../shared/mqtt';


const actions = {
    setMap:'setMap' , setVehiclePlate: 'setVehiclePlate' , setVendorAccessToken: 'setVendorAccessToken'
}



const reducerFunction = (state, action)=>{
    switch(action.type){
        case actions.setMap: {
            return {...state, vid : action.vid, vehiclePlate: null}
        }
        case actions.setVehiclePlate : {
            return {...state, vid: null, vehiclePlate: action.vehiclePlate?.replace(/ +/g,'')}
        }
        case actions.setVendorAccessToken : {
            return {...state, vid: null, vendorAccessToken: action.vendor_access_token}
        }
        default :
            return {...state}
    }
}


function MapPage(props) {
    const [state, dispatchState] = useReducer(reducerFunction, {vid: null, vehiclePlate: null})
    const {vid, vehiclePlate} = state;

    var map = useRef(null);
    const params = new URLSearchParams(window.location.search);
    const vehicleIdQueryParam = params.get('vid');
    const vendorAccessToken = params.get('vendor-access-token');
    const vehiclePlateParam = params.get('plate');
    const {pathname} = useLocation()
    const {vehiclePlate:  vehiclePlateQueryParam} = useParams();


    useEffect(() => {
        initSocket();
    },[])


    useEffect(()=>{
        if(pathname.includes('map')){
            dispatchState({type: actions.setMap, vid: vehicleIdQueryParam});
            dispatchState({type: actions.setVendorAccessToken, vendor_access_token: vendorAccessToken })
            dispatchState({type: actions.setVehiclePlate, vehiclePlate: vehiclePlateParam })
        }else{
            dispatchState({type: actions.setVehiclePlate, vehiclePlate: vehiclePlateQueryParam })
        }
    }, [])

    const setRef = function(ref) {
        map = ref;
    }

    const onVehicleReady = function(latLng) {
        if(map.current)
            map.current.panTo(latLng);
    }
    return (
        <StretchedContainer>
            <MapView
                googleMapURL={MAP_URL}
                setRef={setRef}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                loadingElement={<div style={{ height: `100%` }} />}>
                {
                    (vid || vehiclePlate) &&
                    <VehicleMarkerFromParam vehicleId={vid} vendorAccessToken={vendorAccessToken} vehiclePlate={vehiclePlate} onVehicleReady={onVehicleReady} />
                }
            </MapView>
            <div className="appLogo">
                <img src="https://inroute.intangles.com/css/img/logo/bigger_logo.png"></img>
            </div>
        </StretchedContainer>
    )
}

export default React.memo(MapPage);