import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    html, body {
        height: 100%;
        width: 100%;
    }

    #root {
        height: 100%;
        width: 100%;
    }
`;