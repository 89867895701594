import {
	SET_USER, SET_SPECS_LIST, SET_VEHICLE_LIST, GET_SPECS_LIST,
	GET_SPECS_LIST_FAILED, GET_VEHICLE_LIST, GET_VEHICLE_LIST_FAILED,
	CHANGE_DRAWER_STATE,
	LOGOUT_USER,
	GET_ACCOUNT_SPECS_DATA,
	FETCH_ALL_ACCOUNTS_TO_REALM,
	UPDATE_VEHICLES_IN_REALM,
	FETCH_ACCOUNT_INFO_TO_REALM,
	FETCH_ACCOUNT_INFO_TO_REALM_FAILED,
	FETCH_ACCOUNT_INFO_TO_REALM_SUCCESS,
	GET_VEHICLE_LIST_START,
	GET_VEHICLE_LIST_SUCCESS,
	VEHICLE_MSG_RECEIVED,
	UPDATE_CURRENT_ACCOUNT_VEHICLES,
	GET_DTC_INFO_START,
	GET_DTC_INFO_SUCCESS,
	UPDATE_ADDRESSES,
	FETCH_NOTIFICATIONS_START,
	FETCH_NOTIFICATIONS_SUCCESS,
	TOGGLE_DETAILS_MODAL,
	SET_DETAILS_MODAL_DATA,
	GET_VEHICLE_LIST_FROM_API_FAILED,
	GET_VEHICLE_LIST_FROM_API_SUCCESS,
	GET_VEHICLE_LIST_FROM_API_START,
	SET_NEW_NOTIFICATIONS_INDICATOR,
	SET_NETWORK_INFO,
	UPDATE_USER_START,
	SET_AVAILABLE_ALERT_TYPES,
	SET_REDIRECT_INFO,
	SET_APP_STATE,
	SET_VEHICLE_FILTERS,
	GET_VEHICLE_COUNTS_START,
	GET_VEHICLE_COUNTS_SUCCESS,
    VEHICLE_STATE_CHANGED,
    GET_VEHICLE_BY_ID_FROM_API_START,
    GET_VEHICLE_BY_ID_FROM_API_FAILED,
    GET_VEHICLE_BY_ID_FROM_API_SUCCESS,
	GET_VEHICLE_BY_PLATE_FROM_API,
	GET_VEHICLE_BY_PLATE_FROM_API_SUCCESS,
	VEHICLE_MSG_RECEIVED_FOR_PLATE
} from '../constants';
import { toUIVehicleList } from '../helpers/model';
export function setUserAction(data) {
	if(data.user && data.user.user) {
		data = {
			...data.user
		}
	}
	return {
		type: SET_USER,
		data
	};
}

export function getVehicleList(options,concat) {
	if(concat)
		options.concat = true;
	return {
		concat,
		options,
		type: GET_VEHICLE_LIST_START
	};
}

export function setVehicleList(data,concat) {
	if(concat)
		concat = true;
	return {
		vehicles: data,
		concat,
		type: GET_VEHICLE_LIST_SUCCESS
	};
}

export function getVehicleListFailed(error) {
	return {
		type: GET_VEHICLE_LIST_FAILED,
		error
	};
}

export function getVehicleListFromApi(options,concat) {
	if(concat)
		options.concat = true;
	return {
		concat,
		options,
		type: GET_VEHICLE_LIST_FROM_API_START
	};
}

export function setVehicleListFromApi(data,concat) {
	if(concat)
		concat = true;
	return {
		vehicles: data,
		concat,
		type: GET_VEHICLE_LIST_FROM_API_SUCCESS
	};
}

export function getVehicleListFromApiFailed(error) {
	return {
		type: GET_VEHICLE_LIST_FROM_API_FAILED,
		error
	};
}

export function getSpecsList(account) {
	return {
		type: GET_SPECS_LIST,
		account
	};
}

export function setSpecsList(specs) {
	return {
		type: SET_SPECS_LIST,
		specs
	};
}

export function getSpecsListFailed(error) {
	return {
		type: GET_SPECS_LIST_FAILED,
		error
	};
}

export function changeDrawerState(state) {
	return {
		type: CHANGE_DRAWER_STATE,
		state
	};
}

export function logoutAction()  {
	return {
		type: LOGOUT_USER
	}
}

export function getAccountSpecsData() {
	return {
		type: GET_ACCOUNT_SPECS_DATA
	}
}

export function fetchAllAccountsToRealm() {
	return {
		type: FETCH_ALL_ACCOUNTS_TO_REALM
	}
}

export function updateVehiclesInRealm(updateFrom,list) {
	return {
		type: UPDATE_VEHICLES_IN_REALM,
		updateFrom,
		list
	}
}


export function fetchAccountInfoToRealm(accountId, setAccount, invokeInit) {
    return {
        type: FETCH_ACCOUNT_INFO_TO_REALM,
		accountId,
		setAccount,
		invokeInit
    }
}

export function fetchAccountInfoToRealmFailed(error) {
    return {
        type: FETCH_ACCOUNT_INFO_TO_REALM_FAILED,
        error
    }
}

export function fetchAccountInfoToRealmSuccess(error) {
    return {
        type: FETCH_ACCOUNT_INFO_TO_REALM_SUCCESS
    }
}


export function vehicleMsgReceived(msg) {
	return {
		msg,
		type: VEHICLE_MSG_RECEIVED
	}
}
export function vehicleMsgReceivedForPlate(msg, vehiclePlate) {
	return {
		type: VEHICLE_MSG_RECEIVED_FOR_PLATE,
		msg,
		vehiclePlate,
	}
}

export function vehicleStateChangeReceived(msg) {
	return {
		msg,
		type: VEHICLE_STATE_CHANGED,
	}
}

export function updateCurrentAccountVehicles(data) {
	return {
		type: UPDATE_CURRENT_ACCOUNT_VEHICLES,
		data
	}
}

export function getDtcInfo(dtcs, manufacturer, protocol) {
	return {
		type: GET_DTC_INFO_START,
		dtcs,
		manufacturer,
		protocol
	}
}
export function getDtcInfoSuccess(dtcInfo) {
	return {
		type: GET_DTC_INFO_SUCCESS,
		dtcInfo
	}
}
export function updateAddresses(addresses) {
	return {
		type: UPDATE_ADDRESSES,
		addresses
	}
}
export function fetchNotification() {
	return {
		type: FETCH_NOTIFICATIONS_START
	}
}
export function fetchNotificationSuccess(count) {
	return {
		type: FETCH_NOTIFICATIONS_SUCCESS,
		count
	}
}
export function toggleDetailsModal(isVisible) {
	return {
		type: TOGGLE_DETAILS_MODAL,
		isVisible
	}
}

export function setDetailsModalData(data) {
	return {
		type: SET_DETAILS_MODAL_DATA,
		data
	}
}

export function setNewNotificationsIndicator(data) {
	return {
		type: SET_NEW_NOTIFICATIONS_INDICATOR,
		data
	}
}

export function setNetworkInfo(data={}) {
	return {
		type: SET_NETWORK_INFO,
		data
	}
}

export function updateUser(data={}, updateLocally=false) {
	return {
		type: UPDATE_USER_START,
		data,
		updateLocally
	}
}

export function availableAlertTypesAction(data) {
	return {
		type: SET_AVAILABLE_ALERT_TYPES,
		data
	}
}

export function setRedirectInfo(data) {
	return {
		type: SET_REDIRECT_INFO,
		data
	}
}

export function setAppState(active) {
	return {
		type: SET_APP_STATE,
		active
	}
}

export function setVehicleFilter(filters) {
	return {
		type: SET_VEHICLE_FILTERS,
		filters
	}
}

export function getVehicleCount() {
	return {
		type: GET_VEHICLE_COUNTS_START
	}
}

export function setVehicleCount(counts) {
	return {
		type: GET_VEHICLE_COUNTS_SUCCESS,
		counts
	}
}

export function getVehicleCountFailed() {
	return {
		type: GET_VEHICLE_LIST_FAILED
	}
}

export function getVehicleByIdFromApiAction(vehicleId, options) {
    return {
        type: GET_VEHICLE_BY_ID_FROM_API_START,
        vehicleId,
        options
    }
}

export function getVehicleByPlateFromApiAction(vehiclePlate, vendorAccessToken, options){
	return {
		type : GET_VEHICLE_BY_PLATE_FROM_API,
		vehiclePlate,
		vendorAccessToken,
		options
	}
}
export function getVehicleByPlateSuccess(vehiclePlate, vehicles){
	return {
		type : GET_VEHICLE_BY_PLATE_FROM_API_SUCCESS,
		vehiclePlate,
		vehicles, 
	}
}

export function getVehicleByIdFromApiFailed(error) {
    return {
        type: GET_VEHICLE_BY_ID_FROM_API_FAILED,
        error
    }
}

export function getVehicleByIdFromApiSuccess(data) {
    return {
        type: GET_VEHICLE_BY_ID_FROM_API_SUCCESS,
        data
    }
}
