let BASE_URL_VAR = null;
let env = process.env.NODE_ENV;
export const NAVBAR_HEIGHT = 56;
switch (env) {
	case 'development': {
		BASE_URL_VAR = 'https://apis.intangles.com/';
		break;
	}
	case 'dev': {
		BASE_URL_VAR = 'https://apis.intangles.com/';
		break;
	}
	case 'local': {
		BASE_URL_VAR = 'http://localhost:3000/';
		break;
	}
	case 'production': {
		BASE_URL_VAR = 'https://apis.intangles.com/';
		break;
	}
	case 'prod': {
		BASE_URL_VAR = 'https://apis.intangles.com/';
		break;
	}
	case 'blue': {
		BASE_URL_VAR = 'http://blue-apis.intangles.com/';
		break;
	}
	default: {
		BASE_URL_VAR = 'https://apis.intangles.com/';
	}
}

// export let BASE_URL = "http://192.168.1.142:3000/";
export let BASE_URL = BASE_URL_VAR;
export let SOCKET_URL = 'https://live-data.intangles.com/';
export let MQTT_URL = 'wss://events-server.intangles.com';
// Initial State
export let initialState = {
	user: {},
	vehicles: {
		filters: {
			spec: undefined,
			query: {
				query: ""
			},
			obd: true,
			lastLoc: true,
			status: '*'
		}
	},
	addresses: {},
	polylines: {},
	specs: {},
	accountConfig: {},
	drawerState: 'close',
	dtcs: {},
	notifications: {},
	netInfo: {}
};

//MQTT EVENTS

export const MQTT_EVENTS = {
	VEHICLE_LOC_DATA: 'vehicle_loc_data',
	STATE_CHANGE: 'state_change'
} 

//Vehicle Status
export const MOVING_VEHICLE = 'moving';
export const IDLING_VEHICLE = 'idling';
export const OUT_OF_NETWORK_VEHICLE = 'out_of_network';
export const OFFLINE = 'offline';
export const STOPPED_VEHICLE = 'stopped';

// Actions
export const SET_USER = 'SET_USER';
export const GET_VEHICLE_LIST_START = 'GET_VEHICLE_LIST';
export const GET_VEHICLE_LIST_FAILED = 'GET_VEHICLE_LIST_FAILED';
export const GET_VEHICLE_LIST_SUCCESS = 'GET_VEHICLE_LIST_SUCCESS';
export const SET_VEHICLE_LIST = 'SET_VEHICLE_LIST';
export const GET_SPECS_LIST = 'GET_SPECS_LIST';
export const GET_SPECS_LIST_FAILED = 'GET_SPECS_LIST_FAILED';
export const SET_SPECS_LIST = 'SET_SPECS_LIST';
export const GET_ACCOUNT_DATA_FAILED = 'GET_ACCOUNT_DATA_FAILED';
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
export const GET_ACCOUNT_DATA = 'GET_ACCOUNT_DATA';
export const GET_ACCOUNT_SPECS_DATA = 'GET_ACCOUNT_SPECS_DATA';
export const GET_ACCOUNT_SPECS_DATA_FAILED = 'GET_ACCOUNT_SPECS_DATA_FAILED';
export const GET_ACCOUNT_SPECS_DATA_SUCCESS = 'GET_ACCOUNT_SPECS_DATA_SUCCESS';
export const CHANGE_DRAWER_STATE = 'CHANGE_DRAWER_STATE';
export const MAP_ICON_PATH = '../../static/img/carmarker/bus@0.3x.png';
export const MAP_ICON_BASE_URL = "https://inroute.intangles.com/";
export const MAP_KEY = 'AIzaSyD_ImyAj6KNEtPcmbgVhHnzRbxvi-Qh8RA';
export const MAP_URL = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=' + 'AIzaSyB-UWylaoP7bqyRI-LbyPFY-7D0vm9zk20';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_ALL_ACCOUNTS_TO_REALM = 'FETCH_ALL_ACCOUNTS_TO_REALM';
export const FETCH_ALL_ACCOUNTS_TO_REALM_FAILED = 'FETCH_ALL_ACCOUNTS_TO_REALM_FAILED';
export const FETCH_ALL_ACCOUNTS_TO_REALM_SUCCESS = 'FETCH_ALL_ACCOUNTS_TO_REALM_SUCCESS';
export const UPDATE_VEHICLES_IN_REALM = 'UPDATE_VEHICLES_IN_REALM';
export const UPDATE_VEHICLES_IN_REALM_FAILED = 'UPDATE_VEHICLES_IN_REALM_FAILED';
export const UPDATE_VEHICLES_IN_REALM_SUCCESS = 'UPDATE_VEHICLES_IN_REALM_SUCCESS';
export const FETCH_ACCOUNT_INFO_TO_REALM = 'FETCH_ACCOUNT_INFO_TO_REALM';
export const FETCH_ACCOUNT_INFO_TO_REALM_FAILED = 'FETCH_ACCOUNT_INFO_TO_REALM_FAILED';
export const FETCH_ACCOUNT_INFO_TO_REALM_SUCCESS = 'FETCH_ACCOUNT_INFO_TO_REALM_SUCCESS';
export const VEHICLE_MSG_RECEIVED = 'VEHICLE_MSG_RECEIVED';
export const VEHICLE_MSG_RECEIVED_FOR_PLATE = 'VEHICLE_MSG_RECEIVED_FOR_PLATE';
export const VEHICLE_STATE_CHANGED = 'VEHICLE_STATE_CHANGED';
export const UPDATE_CURRENT_ACCOUNT_VEHICLES = 'UPDATE_CURRENT_ACCOUNT_VEHICLES';
export const UPDATE_CURRENT_ACCOUNT_VEHICLES_SUCCESS = 'UPDATE_CURRENT_ACCOUNT_VEHICLES_SUCCESS';
export const UPDATE_CURRENT_ACCOUNT_VEHICLES_FAILED = 'UPDATE_CURRENT_ACCOUNT_VEHICLES_FAILED';
export const GET_DTC_INFO_START = "GET_DTC_INFO_START";
export const GET_DTC_INFO_SUCCESS = "GET_DTC_INFO_SUCCESS";
export const GET_DTC_INFO_FAILED = 'GET_DTC_INFO_FAILED';
export const UPDATE_ADDRESSES = 'UPDATE_ADDRESSES';
export const FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILED = 'FETCH_NOTIFICATIONS_FAILED';
export const GET_VEHICLE_LIST_FROM_API_START = 'GET_VEHICLE_LIST_FROM_API_START';
export const GET_VEHICLE_LIST_FROM_API_SUCCESS = 'GET_VEHICLE_LIST_FROM_API_SUCCESS';
export const GET_VEHICLE_LIST_FROM_API_FAILED = 'GET_VEHICLE_LIST_FROM_API_FAILED';
export const SET_NEW_NOTIFICATIONS_INDICATOR = 'SET_NEW_NOTIFICATIONS_INDICATOR';
export const SET_NETWORK_INFO = 'SET_NETWORK_INFO';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const SET_AVAILABLE_ALERT_TYPES = 'SET_AVAILABLE_ALERT_TYPES';
export const SET_REDIRECT_INFO = 'SET_REDIRECT_INFO';
export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_VEHICLE_FILTERS = 'SET_VEHICLE_FILTERS';
export const GET_VEHICLE_COUNTS_START = 'GET_VEHICLE_COUNTS_START';
export const GET_VEHICLE_COUNTS_SUCCESS = 'GET_VEHICLE_COUNTS_SUCCESS';
export const GET_VEHICLE_COUNTS_FAILED = 'GET_VEHICLE_COUNTS_FAILED';
export const GET_VEHICLE_BY_ID_FROM_API_START = 'GET_VEHICLE_BY_ID_FROM_API_START';
export const GET_VEHICLE_BY_ID_FROM_API_SUCCESS = 'GET_VEHICLE_BY_ID_FROM_API_SUCCESS';
export const GET_VEHICLE_BY_ID_FROM_API_FAILED = 'GET_VEHICLE_BY_ID_FROM_API_FAILED';
export const GET_VEHICLE_BY_PLATE_FROM_API = 'GET_VEHICLE_BY_PLATE_FROM_API';
export const GET_VEHICLE_BY_PLATE_FROM_API_SUCCESS = 'GET_VEHICLE_BY_PLATE_FROM_API_SUCCESS';
// Threads
export const FETCH_ALL_ACCOUNTS_EVENT = 'FETCH_ALL_ACCOUNTS_EVENT';
export const FETCH_ADDRESS_START = 'FETCH_ADDRESS_START';
export const defaultRegion = {
	latitude: 18.5204,
	longitude: 73.8567,
	latitudeDelta: 1,
	longitudeDelta: 1,
}

export const UserTypes = {
	SI: 'system_integrator',
	INTANGLES: 'intangles',
	CUSTOMER: 'customer'
}

// AsynStorage Keys
export const ACCOUNTS_SYNC_TIME = 'ACCOUNTS_SYNC_TIME';
export const VEHICLES_SYNC_TIME_PREFIX = 'VEHICLES_SYNC_TIME/account-';
export const SPECS_SYNC_TIME_PREFIX = 'SPECS_SYNC_TIME/account-'
export const CURRENT_ACCOUNT = 'CURRENT_ACCOUNT';
export const ACCESSED_ACCOUNTS = 'ACCESSED_ACCOUNTS';
export const NOTIFICATIONS_SYNC_TIME_PREFIX = 'NOTIFICATIONS_SYNC_TIME/account-';
export const NOTIFICATIONS_SYNC_TIME = 'NOTIFICATIONS_SYNC_TIME';
export const TOGGLE_DETAILS_MODAL = 'TOGGLE_DETAILS_MODAL';
export const SET_DETAILS_MODAL_DATA = 'SET_DETAILS_MODAL_DATA';
export const AVAILABLE_ALERT_TYPES = 'AVAILABLE_ALERT_TYPES';
export const VERIFICATION_SKIPPED = 'VERIFICATION_SKIPPED';
//Others
export const dayStrings = [
	{
		long: 'Sunday',
		short: 'Sun'
	},
	{
		long: 'Monday',
		short: 'Mon'
	},
	{
		long: 'Tuesday',
		short: 'Tue'
	},
	{
		long: 'Wednesday',
		short: 'Wed'
	},
	{
		long: 'Thursday',
		short: 'Thu'
	},
	{
		long: 'Friday',
		short: 'Fri'
	},
	{
		long: 'Saturday',
		short: 'Sat'
	}]

// AlertTypes
export const AlertTypes = {
	IDLING_START: 'idling_start', // done
	FUEL_BHARA: 'fuel_bhara', // done
	FUEL_CHORI: 'fuel_chori', // done
	FUEL_LOW_LEVEL: 'fuel_low_level', // done
	DEF_LOW_LEVEL: 'def_low_level', // done
	DTC: 'dtc', // done
	FREERUN: 'freerun', // done
	SPEEDING: 'speeding', // done
	UNSCHEDULED_DRIVING: 'unscheduled_driving',
	DEVICE_CONNECTED: 'device_connected', // done
	DEVICE_DISCONNECTED: 'device_disconnected', // done
	DEV_DISCONN: 'dev_disconn', // done
	ALGO_OUTPUT: 'algo_output',
	PANICK: 'panick', // done
	HEALTH_CHANGE: 'health_change',// ignore
	SCHEDULE_INFO: 'schedule_info', // ignore
	OBD_NOT_WORKING: 'obd_not_working', // done
	NOTIFY_OPERATIONS_ACCOUNT_VEHICLE_MISSING: 'notify_operations_account_vehicle_missing', // ignore
	UNAUTHORISED_FUEL_FILLING: 'unauthorised_fuel_filling', // done
	UNAUTHORISED_STOPPAGE: 'unauthorized_stoppage_start'
}
export const notificationTypes = [
	AlertTypes.DTC,
	AlertTypes.FREERUN,
	AlertTypes.FUEL_BHARA,
	AlertTypes.FUEL_CHORI,
	AlertTypes.SPEEDING,
	AlertTypes.DEF_LOW_LEVEL,
	AlertTypes.FUEL_LOW_LEVEL,
	AlertTypes.IDLING_START,
	AlertTypes.DEV_DISCONN,
	AlertTypes.DEVICE_CONNECTED,
	AlertTypes.DEVICE_DISCONNECTED,
	AlertTypes.PANICK,
	//AlertTypes.SCHEDULE_INFO,
	AlertTypes.UNAUTHORISED_FUEL_FILLING,
	AlertTypes.UNSCHEDULED_DRIVING,
	AlertTypes.UNAUTHORISED_STOPPAGE,
]

export const AlertInfo = {
	[AlertTypes.FUEL_BHARA]: {
		label: 'Fuel Filling',
		group: 'fuel'
	},
	[AlertTypes.FUEL_CHORI]: {
		label: 'Fuel Theft',
		group: 'fuel'
	},
	[AlertTypes.FUEL_LOW_LEVEL]: {
		label: 'Fuel Low Level',
		group: 'fuel'
	},
	[AlertTypes.UNAUTHORISED_FUEL_FILLING]: {
		label: 'Unauthorised Fuel Filling',
		group: 'fuel'
	},
	[AlertTypes.FREERUN]: {
		label: 'Freerun',
		group: 'freerun'
	},
	[AlertTypes.HEALTH_CHANGE]: {
		label: 'health_change',
		group: 'health'
	},
	[AlertTypes.ALGO_OUTPUT]: {
		label: 'Algo Output',
		group: 'algo'
	},
	[AlertTypes.DEV_DISCONN]: {
		label: 'Device Disconnected',
		group: 'device'
	},
	[AlertTypes.DEVICE_CONNECTED]: {
		label: 'Device Connected',
		group: 'device'
	},
	[AlertTypes.DEVICE_DISCONNECTED]: {
		label: 'Device Disconnected',
		group: 'device'
	},
	[AlertTypes.DEF_LOW_LEVEL]: {
		label: 'DEF Low Level',
		group: 'def'
	},
	[AlertTypes.DTC]: {
		label: 'DTC',
		group: 'dtc'
	},
	[AlertTypes.UNSCHEDULED_DRIVING]: {
		label: 'Unscheduled Driving',
		group: 'unscheduled_driving'
	},
	[AlertTypes.PANICK]: {
		label: 'Panick',
		group: 'panick'
	},
	[AlertTypes.IDLING_START]: {
		label: 'Idling',
		group: 'idling'
	},
	[AlertTypes.SPEEDING]: {
		label: 'Speeding',
		group: 'speeding'
	},
	[AlertTypes.UNAUTHORISED_STOPPAGE]: {
		label: 'Unauthorised Stoppage',
		group: 'stoppage'
	}
}

export const AlertGroups = {
	'fuel': {
		alerts: [
			AlertTypes.FUEL_BHARA,
			AlertTypes.FUEL_CHORI,
			AlertTypes.FUEL_LOW_LEVEL,
			AlertTypes.UNAUTHORISED_FUEL_FILLING
		],
		title: 'Fuel'
	},
	'def': {
		alerts: [AlertTypes.DEF_LOW_LEVEL],
		title: 'AdBlue'
	},
	'idling': {
		alerts: [AlertTypes.IDLING_START],
		title: 'Idling',
	},
	'stoppage': {
		alerts: [AlertTypes.UNAUTHORISED_STOPPAGE],
		title: 'Stoppage',
	},
	'dtc': {
		alerts: [AlertTypes.DTC],
		title: 'DTC'
	},
	'freerun': {
		alerts: [AlertTypes.FREERUN],
		title: 'Freerun'
	},
	'speeding': {
		alerts: [AlertTypes.SPEEDING],
		title: 'Speeding'
	},
	'device': {
		alerts: [
			AlertTypes.DEV_DISCONN,
			AlertTypes.DEVICE_CONNECTED,
			AlertTypes.DEVICE_DISCONNECTED
		],
		title: 'Device'
	},
	'algo': {
		alerts: [AlertTypes.ALGO_OUTPUT],
		title: 'Algo'
	},
	'panick': {
		alerts: [AlertTypes.PANICK],
		title: 'Panick'
	},
	'schedule': {
		alerts: [AlertTypes.SCHEDULE_INFO],
		title: 'Schedule'
	},
	'health': {
		alerts: [AlertTypes.HEALTH_CHANGE],
		title: 'Health'
	},
	'unscheduled_driving': {
		alerts: [AlertTypes.UNSCHEDULED_DRIVING],
		title: 'Unscheduled Driving'
	}
}

export const NOTIFICATION_TABS = {
	DTC: 'dtc',
	IDLING: 'idling',
	STOPPAGE: 'stoppage',
	DEF: 'def',
	FUEL: 'fuel',
	SPEEDING: 'speeding',
	FREERUN: 'freerun',
	DEVICE: 'device',
	PANICK: 'panick',
	ALGO: 'algo'
}

export const DONT_DISPLAY_THESE_TABS = [];

export const ALERT_GROUPS_GET_FROM_REALM = [
	NOTIFICATION_TABS.DTC,
	NOTIFICATION_TABS.IDLING,
	NOTIFICATION_TABS.STOPPAGE,
	NOTIFICATION_TABS.DEF,
	NOTIFICATION_TABS.FUEL,
	NOTIFICATION_TABS.SPEEDING,
	NOTIFICATION_TABS.FREERUN,
	NOTIFICATION_TABS.DEVICE,
	NOTIFICATION_TABS.PANICK,
	NOTIFICATION_TABS.ALGO
];

export const NotificationOrder = [
	NOTIFICATION_TABS.DTC,
	NOTIFICATION_TABS.ALGO,
	NOTIFICATION_TABS.FUEL,
	NOTIFICATION_TABS.DEVICE,
	NOTIFICATION_TABS.PANICK,
	NOTIFICATION_TABS.IDLING,
	NOTIFICATION_TABS.STOPPAGE,
	NOTIFICATION_TABS.DEF,
	NOTIFICATION_TABS.SPEEDING,
	NOTIFICATION_TABS.FREERUN
];

export const NotificationRedirectingToLocation = [
	AlertTypes.FUEL_BHARA,
	AlertTypes.FUEL_CHORI,
	AlertTypes.IDLING_START,
	AlertTypes.SPEEDING,
	AlertTypes.FREERUN,
	AlertTypes.PANICK,
	AlertTypes.DEV_DISCONN,
	AlertTypes.DEVICE_CONNECTED,
	AlertTypes.DEVICE_DISCONNECTED,
	AlertTypes.UNAUTHORISED_FUEL_FILLING,
	AlertTypes.UNAUTHORISED_STOPPAGE
]

export const TNC_URL = 'https://s3.ap-south-1.amazonaws.com/assets.intangles.com/terms-and-conditions/Intangles-Customer-Terms-and-Conditions.pdf'