import { normalize, schema } from 'normalizr';
import { reverseVehicleObject } from '../../db/helpers/getVehicleObject';
import { getShowableString, toTitleCase } from './model';
// import { reverseNotificationObject } from '../../db/helpers/getNotificationObject';

const vehicle = new schema.Entity('vehicles',{}, {
    processStrategy: (value) => reverseVehicleObject(value)
});

const account = new schema.Entity('accounts',{});

const spec = new schema.Entity('specs',{}, {
    processStrategy: (spec) => {
        spec.display_manufacturer = getShowableString(spec.manufacturer);
        spec.display_model = toTitleCase(spec.model);
        if (spec && spec.code_versions) {
            spec.code_versions.map(function (o) {
                if (o.name == 'L1')
                    return spec.L1 = o;
                if (o.name == 'L2')
                    return spec.L2 = o;
                if (o.name == 'M66' || o.name == 'm66')
                    return spec.m66 = o;
            });
        }
        return {
            ...spec
        }
    }
});

// const notification = new schema.Entity('notifications', {} , {
//     processStrategy: reverseNotificationObject
// });
// Define a users schema
export function normalizeVehicles(data) {
    var dat = normalize(data, { list: [vehicle] });
    return dat;
}

export function normalizeSpecs(data) {
    var dat = normalize(data, { specs: [ spec ] });
    return dat;
}

// export function normalizeNotificatons(data) {
//     var dat = normalize(data, { notifications: [ notification ]});
//     return dat;
// }

// export function normalizeNotificationsByType(data, types) {
//     var returnObj = {};
//     types.forEach(function(type) {
//         returnObj[type] = normalize(data[type], { notifications: [ notification ] });
//     });
//     return returnObj;
// }

export function normalizeAccounts(data) {
    var dat = normalize(data, { accounts: [ account ]});
    return dat;
}

export function normalizeAccountsByStage(data, stages = []) {
    var returnObj = {};
    stages.forEach(function(stage) {
        returnObj[stage.toLowerCase()] = normalize(data[stage.toLowerCase()], { accounts: [ account ] });
    });
    return returnObj;
}