import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import navReducer from './reducers/nav.reducer';
// import accountReducer from '../screens/AccountScreen/reducers/accounts.reducer';
// import loginReducer from '../screens/LoginScreen/reducers/login.reducer';
import rootSaga from './rootSaga';
// import { navMiddleware } from '../navigationHelpers';
// import fleetHealthReducer from '../screens/FleetHealth/reducers/fleetHealth.reducer';
// import mapReducer from '../screens/MapScreen/reducers/map.reducers';
// import notificationReducer from '../screens/NotificationScreen/reducers/notification.reducer';
// import timeFilterReducer from '../screens/TimeFilterScreen/reducers/timeFilter.reducer';
// import signUpReducer from '../screens/SignUpScreen/reducers/signUp.reducer';
import { LOGOUT_USER } from './constants';
import appReducer from './reducers/app.reducer';
//fetching reducers
var initialMeta = {
    reducers: {
        app: appReducer,
        // login: loginReducer,
        // nav: navReducer,
        // account: accountReducer,
        // fleetHealth: fleetHealthReducer,
        // map: mapReducer,
        // notification: notificationReducer,
        // timeFilter: timeFilterReducer,
        // signUp: signUpReducer
    },
    sagas: []
};
//saga middleware
var sagaMiddleware = createSagaMiddleware()
var storeCreator = createStore;
let middlewares = [];
let enhancer = null;
// Devtools
if (process.env.NODE_ENV === 'development' ) {
    // var Reactotron = require('./ReactotronConfig').default;
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);
    // storeCreator = Reactotron.createStore;
    // sagaMiddleware = createSagaMiddleware({ sagaMonitor: Reactotron.createSagaMonitor() })
}
middlewares.push(sagaMiddleware);
// middlewares.push(navMiddleware);
enhancer = compose(
    applyMiddleware(...middlewares),
)
function makeStore (initialState = {}) {
    let store = null;
    const appReducer = combineReducers(initialMeta.reducers);
      
    const rootReducer = (state, action) => {
        if (action.type === LOGOUT_USER) {
            state = undefined
        }
        return appReducer(state, action)
    }
    let composeEnhancers = compose;
    // if(__DEV__)
    //     composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(
        applyMiddleware(...middlewares),
    )
    store = storeCreator(
        rootReducer,
        initialState,
        enhancer
    )
    sagaMiddleware.run(rootSaga);
    return store;
}
export default makeStore();