import {all,fork} from 'redux-saga/effects';
import vehicleListSaga from './sagas/vehicleList.saga';
export default function  * rootSaga(){
	yield all([
		// fork(loginSaga),
		// fork(accountsSaga),
		// fork(fleetHealthSaga),
		fork(vehicleListSaga),
		// fork(specsListSaga),
		// fork(getAccountSpecsDataSaga),
		// fork(fetchAllAccountsToRealmSaga),
		// fork(updateVehiclesInRealmSaga),
		// fork(fetchAccountInfoToRealmSaga),
		// fork(updateCurrentAccountVehiclesSaga),
		// fork(getDtcInfoSaga),
		// fork(fetchNotificationSaga),
		// fork(notificationsSaga),
		// fork(signUpFormSagas),
		// fork(updateUserSaga)
	]);
}