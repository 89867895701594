import { cloneDeep } from 'lodash';
export default function getVehicleObject(vehicle = {}) {
    if (vehicle.last_state)
        vehicle.__last_state = JSON.stringify(vehicle.last_state);
    if (vehicle.location)
        vehicle.__location = JSON.stringify(vehicle.location);
    if (vehicle.drivers)
        vehicle.__drivers = JSON.stringify(vehicle.drivers);
    if (vehicle.location)
        vehicle.__location = JSON.stringify(vehicle.location);
    if (vehicle.health_info)
        vehicle.__health_info = JSON.stringify(vehicle.health_info);
    if (vehicle.health_algos)
        vehicle.__health_algos = JSON.stringify(vehicle.health_algos);
    if (vehicle.dtcs_new)
        vehicle.__dtcs_new = JSON.stringify(vehicle.dtcs_new);
    if (vehicle.dtcs)
        vehicle.__dtcs = JSON.stringify(vehicle.dtcs);
    if (vehicle.idevice)
        vehicle.__idevice = JSON.stringify(vehicle.idevice);
    if (vehicle.trip)
        vehicle.__trip = JSON.stringify(vehicle.trip);
    if (vehicle.no_info_dtcs)
        vehicle.__no_info_dtcs = JSON.stringify(vehicle.no_info_dtcs);
    if (vehicle.obd_odo)
        vehicle.__obd_odo = JSON.stringify(vehicle.obd_odo);
    if (vehicle.status_object)
        vehicle.__status_object = JSON.stringify(vehicle.status_object);
    if (vehicle.fuel_info)
        vehicle.__fuel_info = JSON.stringify(vehicle.fuel_info);
    if (vehicle.fuel)
        vehicle.__fuel = JSON.stringify(vehicle.fuel);
    return vehicle;
}

export function reverseVehicleObject(v = {}) {
    var vehicle = {...v};
    if (vehicle.__last_state)
        vehicle.last_state = JSON.parse(vehicle.__last_state);
    if (vehicle.__location)
        vehicle.location = JSON.parse(vehicle.__location);
    if (vehicle.__drivers)
        vehicle.drivers = JSON.parse(vehicle.__drivers);
    if (vehicle.__location)
        vehicle.location = JSON.parse(vehicle.__location);
    if (vehicle.__health_info)
        vehicle.health_info = JSON.parse(vehicle.__health_info);
    if (vehicle.__health_algos)
        vehicle.health_algos = JSON.parse(vehicle.__health_algos);
    if (vehicle.__dtcs_new)
        vehicle.dtcs_new = JSON.parse(vehicle.__dtcs_new);
    if (vehicle.__dtcs)
        vehicle.dtcs = JSON.parse(vehicle.__dtcs);
    if (vehicle.__idevice) {
        vehicle.idevice = JSON.parse(vehicle.__idevice);
        vehicle.deviceId = vehicle.idevice.id;
    }
    if (vehicle.__trip)
        vehicle.trip = JSON.parse(vehicle.__trip);
    if (vehicle.__no_info_dtcs)
        vehicle.no_info_dtcs = JSON.parse(vehicle.__no_info_dtcs);
    if (vehicle.__obd_odo)
        vehicle.obd_odo = JSON.parse(vehicle.__obd_odo);
    if (vehicle.__status_object)
        vehicle.status_object = JSON.parse(vehicle.__status_object);
    if (vehicle.__fuel)
        vehicle.fuel = JSON.parse(vehicle.__fuel);
    if (vehicle.__fuel_info)
        vehicle.fuel_info = JSON.parse(vehicle.__fuel_info);
    return vehicle;
}