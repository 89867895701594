import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import NestedRouter from './NestedRouter';
import Page404 from './pages/404';
function MainRouter(props) {
    return (
        <Router>
            <Switch>
                <Route path="/404" exact component={Page404}/>
                <Route path="/" component={NestedRouter}/>
                <Route path="*" component={Page404}/>
            </Switch>
        </Router>
    )
}

export default MainRouter;