import React from 'react';
import GlobalStyle from './styles/globalStyles';
import MainRouter from './MainRouter';
import MainContainer from './styled/MainContainer';
import { ThemeProvider } from 'styled-components';
import StretchedContainer from './styled/StretchedContainer';
import { Provider } from 'react-redux';
import makeStore from '../shared/makeStore';
import "./css/App.css"

function App(){
    return (
      <Provider store={makeStore}>
        <ThemeProvider theme={{}}>
          <StretchedContainer>
            <GlobalStyle />
            <MainContainer>
              <MainRouter />
            </MainContainer>
          </StretchedContainer>
        </ThemeProvider>
      </Provider>
    );
}


export default App;
