import { toUIDTC, getCodesSummary, getTimeForReport, secondsToMinutes } from '../helpers/model';
import { MAP_ICON_BASE_URL, OUT_OF_NETWORK_VEHICLE, OFFLINE } from '../constants';
import theme from '../../theme/theme';
var { HEALTH_GOOD, HEALTH_MINOR, HEALTH_MAJOR, HEALTH_CRITICAL } = theme;
export default class Vehicle {
    constructor(from) {
        if(!from)
            return;
        this.id = from.id;
        if (from.idevice && from.idevice.id)
            this.deviceId = from.idevice.id; //device id
        // this.name = from.name;
        this.plate = from.plate;
        this.tagged = from.tagged;
        if (from.last_state) {
            this.lastLoc = from.last_state.loc;
            this.battery = from.last_state.battery;
            this.timestamp = from.last_state.timestamp;
            this.hd = from.last_state.hd;
            this.speed = from.last_state.sp;
            this.sp = from.last_state.sp;
            this.connectionStatus = from.last_state.exb;
        }
        else if (from.gps_info) {
            this.lastLoc = from.gps_info.loc;
            this.battery = from.gps_info.battery;
            this.timestamp = from.gps_info.timestamp;
            this.hd = from.gps_info.hd;
            this.speed = from.gps_info.sp;
            this.sp = from.gps_info.sp;
            this.connectionStatus = from.gps_info.exb;
        }
        if (from.vin) {
            this.vin = from.vin;
        }
        if (from.obd_attached === true) {
            this.obd_attached = true;
        } else {
            this.obd_attached = false;
        }
        if (from.fuel_level_enabled === true) {
            this.fuel_level_enabled = true;
        } else {
            this.fuel_level_enabled = false;
        }
        if (from.idevice && from.idevice.imei) {
            this.imei = from.idevice.imei;
        } else {
            this.imei = '';
        }
        this.manufacturer = from.manufacturer;
        if (from.spec_id)
            this.spec_id = from.spec_id;
        else
            this.spec_id = "";
        if (from.tags) {
            this.tags = from.tags;
            if (from.tags.indexOf('R23') > -1)
                this.R23 = true;
            if (from.tags.indexOf('for_deployment') > -1)
                this.for_deployment = true;
            if (from.tags.indexOf("BAD DEF SENSOR") > -1 || from.tags.indexOf("BAD_DEF_SENSOR") > -1)
                this.bad_def_sensor = true;
            if (from.tags.indexOf("bad_fuel_sensor") > -1)
                this.bad_fuel_sensor = true;
            if (from.tags.indexOf("cusfs") > -1 || from.tags.indexOf("usfs") > -1)
                this.isUsfsVehicle = true;
            if (from.tags.indexOf("fuel_sensor_change") > -1 || from.tags.indexOf("fuel_sensor_change") > -1)
                this.fuel_sensor_change = true;
        }

        if (from.is_adblue) {
            this.adblue_enabled = from.is_adblue;
            this.is_adblue = from.is_adblue;
        }
        else {
            this.adblue_enabled = false;
            this.is_adblue = false;
        }
        this.event = from.event;
        if (from.protocol) {
            this.protocol = from.protocol;
        }
        if (from.dtc_protocol) {
            this.dtc_protocol = from.dtc_protocol;
        }
        if (from.drivers && from.drivers.length > 0) {
            this.drivers = from.drivers;
            var drivers = '';
            var contact;
            for (var i = 0; i < from.drivers.length; i++) {
                var d = from.drivers[i];
                if (d.name) {
                    // var split = d.name.split(' ');
                    // drivers += split[0] + ' /';
                    if (drivers !== '') {
                        drivers += ' / ';
                    }
                    drivers += d.name;
                }
                if (d.contact) {
                    if (d.contact && d.contact.length > 0) {
                        contact = d.contact[0];
                    }
                }
            }
            this.driver = drivers;
            this.contact = contact;

        }
        // this.driver = 'Neil / Mudassir';
        //     this.contact = '9860693192';
        // {
        //     latitude: from.last_loc.lat,
        //     longitude: from.last_loc.lng
        // },
        this.status = from.status;
        if( this.status && this.status?.state.toLowerCase() === OUT_OF_NETWORK_VEHICLE)
            this.status = OFFLINE;
        this.speed = "0";
        //ui property
        // this.icon = "dist/css/img/carmarker/icon0.png";
        this.showMapBox = true;
        this.routes = [];
        if (from.dtcs) {
            this.dtcs = from.dtcs;
        }
        if (from.dtcs_new) {
            // var dtcs = toUIDTC({ dtcs: from.dtcs_new });
            // if (dtcs && dtcs.dtcs)
            this.dtcs_new = from.dtcs_new;

            this.code_summary = getCodesSummary(from.dtcs_new);

        }
        if (from.health || from.health_info) {
            var h = from.health;
            var updated = undefined;
            if (from.health_info) {
                h = from.health_info.score;
                if (from.health_info.last_update == null || from.health_info.last_update == undefined)
                    updated = '';
                else
                    updated = getTimeForReport(from.health_info.last_update);
            }
            var text = '';
            var color = '';

            switch (h) {
                case 0:
                    text = 'Good';
                    color += HEALTH_GOOD;
                    break;
                case 1:
                    text = 'Minor Issue';
                    color += HEALTH_MINOR;
                    break;
                case 2:
                    text = 'Major Issue';
                    color += HEALTH_MAJOR;
                    break;
                case 3:
                    text = 'Critical Issue';
                    color += HEALTH_CRITICAL;
                    break;
            }
            this.health = {
                text: text,
                healthColor: color,
                val: h,
                updated: updated
            };

        }

        if (from.fuel) {
            if (from.fuel.amount)
                this.amount = from.fuel.amount;

            if (from.fuel.last_update)
                this.last_update = from.fuel.last_update;
        }

        if (from.last_state)
            this.last_state = from.last_state;

        // this.setAlertMarker = function (obj) {
        //     var event = obj.event;
        //     var iconUrl;

        //     var title;
        //     switch (event.type.toLowerCase()) {
        //         case 'speeding':
        //             iconUrl = 'sp_limit_no_text.png';
        //             var speed = Math.round(obj.sp);
        //             var time = getTimeForReport(obj.timestamp);
        //             title = 'Over Speeding ( ' + speed + ' Km/Hr ) at ' + time;
        //             break;
        //         case 'idling':
        //             iconUrl = 'stop_sign.png';
        //             var stopTime = secondsToMinutes(obj.event.idle_time);
        //             var time = getTimeForReport(event.start_time);
        //             title = 'Stopped for ' + stopTime + ' at ' + time;
        //             break;
        //         case 'hard_brake':
        //             iconUrl = 'alert_vehicle_small.png';
        //             title = 'Hard Brake';
        //             break;
        //         case 'over_acceleration':
        //             iconUrl = 'alert_vehicle_small.png';
        //             title = 'Harsh Acceleration';
        //             break;
        //         case 'fuel_bhara':
        //             iconUrl = 'fuel_filled.png';
        //             title = 'Fuel Filled';
        //             break;
        //     }
        //     this.alertMarkerIcon = 'css/img/alertmarkers/' + iconUrl;
        //     this.alertTitle = title;
        // };
        this.setHeading = function (heading) {
            var iconUrl;
            if ((heading >= 0 && heading < 15) || heading === undefined) {
                iconUrl = 'icon0.png';
            } else if (heading >= 15 && heading < 30) {
                iconUrl = 'icon15.png';
            } else if (heading >= 30 && heading < 45) {
                iconUrl = 'icon30.png';
            } else if (heading >= 45 && heading < 60) {
                iconUrl = 'icon45.png';
            } else if (heading >= 60 && heading < 75) {
                iconUrl = 'icon60.png';
            } else if (heading >= 75 && heading < 90) {
                iconUrl = 'icon75.png';
            } else if (heading >= 90 && heading < 105) {
                iconUrl = 'icon90.png';
            } else if (heading >= 105 && heading < 120) {
                iconUrl = 'icon105.png';
            } else if (heading >= 120 && heading < 135) {
                iconUrl = 'icon120.png';
            } else if (heading >= 135 && heading < 150) {
                iconUrl = 'icon135.png';
            } else if (heading >= 150 && heading < 165) {
                iconUrl = 'icon150.png';
            } else if (heading >= 165 && heading < 180) {
                iconUrl = 'icon165.png';
            } else if (heading >= 180 && heading < 195) {
                iconUrl = 'icon180.png';
            } else if (heading >= 195 && heading < 210) {
                iconUrl = 'icon195.png';
            } else if (heading >= 210 && heading < 225) {
                iconUrl = 'icon210.png';
            } else if (heading >= 225 && heading < 240) {
                iconUrl = 'icon225.png';
            } else if (heading >= 240 && heading < 255) {
                iconUrl = 'icon240.png';
            } else if (heading >= 255 && heading < 270) {
                iconUrl = 'icon255.png';
            } else if (heading >= 270 && heading < 285) {
                iconUrl = 'icon270.png';
            } else if (heading >= 285 && heading < 300) {
                iconUrl = 'icon285.png';
            } else if (heading >= 300 && heading < 315) {
                iconUrl = 'icon300.png';
            } else if (heading >= 315 && heading < 330) {
                iconUrl = 'icon315.png';
            } else if (heading >= 330 && heading < 345) {
                iconUrl = 'icon330.png';
            } else if (heading >= 345 && heading < 360) {
                iconUrl = 'icon345.png';
            } else {
                iconUrl = 'icon0.png';
            }
            return MAP_ICON_BASE_URL + "css/img/carmarker/" + iconUrl;
        };

        this.setHeading(this.hd);
        this.fuel_info = from.fuel || from.fuel_info;
        this.obd_odo = from.obd_odo;
        this.vehicle_type = from.vehicle_type;
        this.health_algos = from.health_algos;
        this.health_summary = getHealthSummary(from.health_algos);
        if(from.polyline)
            this.polyline = from.polyline;
        else {
            if (from && from.last_state && from.last_state.loc)
                this.polyline = [
                    {
                        latitude: parseFloat(from.last_state.loc.lat),
                        longitude: parseFloat(from.last_state.loc.lng),
                        lat: parseFloat(from.last_state.loc.lat),
                        lng: parseFloat(from.last_state.loc.lng)
                    }
                ];
        }
    }
}

function getHealthSummary(algos) {
    var healthSummary = {
        healthCount: 0,
        maxHealthStatus: 0
    }
    if(!algos)
        return healthSummary;
    if(algos.bat && algos.bat.algos) {
        if(algos.bat.algos.health_status > 0) {
            healthSummary.healthCount++;
            healthSummary.maxHealthStatus = Math.max(healthSummary.maxHealthStatus, algos.bat.algos.health_status);
        }
    }
    if(algos.ect && algos.ect.algos) {
        if(algos.ect.algos.health_status > 0) {
            healthSummary.healthCount++;
            healthSummary.maxHealthStatus = Math.max(healthSummary.maxHealthStatus, algos.ect.algos.health_status);
        }
    }
    return healthSummary;
}