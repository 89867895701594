import React from 'react';
import {format} from 'date-fns';
import Units from '../../../../shared/units';
import { InfoWindow } from 'react-google-maps';
export default function VehicleInfoWindow(props) {
    var { vehicle = {}, onCloseClick } = props;
    return (
        <InfoWindow style={{ borderRadius: 0 }} onCloseClick={onCloseClick}>
            <div>
                <small>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    Plate:
                                    <b> {vehicle.tagged || vehicle.tag || vehicle.plate}</b>
                                </td>
                                {/* <td>Driver Info:
                                            <b>
                                            {
                                                vehicle.drivers &&
                                                vehicle.drivers.map(function(item, index) {
                                                    return <li key={index}>{item.name + ' - ' + item.phone}</li>
                                                })
                                            }
                                            </b>
                                        </td> */}
                            </tr>
                            <tr>
                                <td>
                                    Speed:
                                    {
                                        vehicle.sp != null ?
                                        <b> {Units.Speed(vehicle.sp, { stringFormat: '<value> <unit>' })}</b> :
                                        <b> {Units.Speed(vehicle.sp, { stringFormat: '- <unit>' })}</b>
                                    }
                                </td>
                                <td>
                                    Date:
                                    <b> {format(vehicle.timestamp, 'do MMM yyyy, H:mm')}</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </small>
            </div>
        </InfoWindow>
    )
}