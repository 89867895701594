import { SOCKET_URL, MQTT_URL, MQTT_EVENTS } from './constants';
import mqtt from 'mqtt';
var client = null;
var onMessage = null;
var onStateChange = null;
var vidsToSubscribe = [];
// global.localStorage = {
//     getItem: (key) => {
//         return AsyncStorage.getItem(key).then(item => item);
//     },
//     setItem: (key, value) => {
//         return AsyncStorage.setItem(key, value).then(item => item);;
//     },
//     removeItem: (key) => {
//         return AsyncStorage.removeItem(key).then(item => item);;
//     },
// };
var ID = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};
export async function initSocket() {
    if (client && client.connected)
        return;
    // var isConnected = await NetInfo.isConnected.fetch();
    // if(isConnected)
    client = mqtt.connect(MQTT_URL, {
        keepalive: 10,
        clientId: 'intangles-app' + ID()
    });
    client.on('connect', function() {
        console.log("Connected to MQTT");
        console.log("Connected to MQTT");
        if(vidsToSubscribe.length > 0)
            vidsToSubscribe.forEach((id) => {
                connectToDevice(id);
            });
    });
    client.on('disconnect', function() {
        console.log("Disconnected");
        console.log("Disconnected");
    });
}

export function connectToDevice(vid, retryAfterConnect) {
     subscribeToLiveLoc(vid, retryAfterConnect);
    subscribeToStateChange(vid, retryAfterConnect);

}

export function disconnectDevice(vid) {
    unsubscribeFromLiveLoc(vid);
    unsubscribeFromStateChange(vid);
}

export function subscribeToLiveLoc(vid) {
    if (client && client.connected) {
        console.log('Connecting to device: ', vid);
        client.subscribe(`live/${vid}/${MQTT_EVENTS.VEHICLE_LOC_DATA}`);
        if(vidsToSubscribe.indexOf(vid) > -1)
            vidsToSubscribe.splice(vidsToSubscribe.indexOf(vid), 1);
    } else
        console.log('Couldn\'t connect to device!');
}

export function unsubscribeFromLiveLoc(vid) {
    if (client.connected) {
        // console.log('Disconnecting device: ', vid);
        client.unsubscribe(`live/${vid}/${MQTT_EVENTS.VEHICLE_LOC_DATA}`);
    }
}

export function subscribeToStateChange(vid, retryAfterConnect) {
    if (client && client.connected) {
        console.log('Connecting for state change: ', vid);
        client.subscribe(`vehicle/${vid}/${MQTT_EVENTS.STATE_CHANGE}`);
    } else {
        if(retryAfterConnect)
            vidsToSubscribe.push(vid);
        console.log('Couldn\'t connect to device!');
    }
}

export function unsubscribeFromStateChange(vid) {
    if (client.connected) {
        // console.log('Disconnecting device: ', vid);
        client.unsubscribe(`vehicle/${vid}/${MQTT_EVENTS.STATE_CHANGE}`);
    }
}



export function onDevicePing(func = () => {}) {
    onMessage = func;
    if(client)
        client.on('message', function(topic,msg) {
            if(!msg)
                return;
            var message = JSON.parse(msg.toString());
            func(topic, message);
        });
}

export function onVehicleStateChange(func = () => {}) {
    onStateChange = func;
    // if(socket)
    //     socket.on('state', function(msg) {
    //         func(msg);
    //     });
} 